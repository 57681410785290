.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #020201;
}

.landing-img {
  width: 50%;
  /* border-radius: 100%; */
  margin-bottom: 2rem;
}

.landing-button {
  background: transparent;
  /* border: 2px solid #fff; */
  color: #fff;
}

.landing-button:hover {
  /* background-image: linear-gradient(to bottom, #59a27d, #345b4b, #567368); */
  color: #fff;
  /* box-shadow: 0 0 20px #59a27d; */

  transition: all 0.3s ease;
}

.color-change-2x {
  font-family: "Cascadia Code", monospace;
  font-size: 1.2rem;
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-23 0:27:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    color: #59a27d;
    text-shadow: 0 0 20px #59a27d;
  }
  100% {
    color: #345b4b;
    text-shadow: 0 0 20px #345b4b;
  }
}
@keyframes color-change-2x {
  0% {
    color: #59a27d;
    text-shadow: 0 0 20px #59a27d;
  }
  100% {
    color: #345b4b;
    text-shadow: 0 0 20px #345b4b;
  }
}

body {
    margin: 0;
    padding: 0;
  }
  
  .container {
    margin: 0;
    padding: 0;
  }
  