@charset "UTF-8";
body {
  font-family: courier;
  background: black;
  overflow: auto;
  margin: 0;
  padding: 0;
  text-align: center;



}
.matrix-container {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

  

.matrix-mobile {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #80ff80;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.matrix li:nth-child(1) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(1):before {
  display: block;
  width: 1em;
  content: "ょ ョ た わ ョ ト わ ウ ウ ラ ン ソ ょ ょ わ ワ ン フ ョ た こ ん シ ン";
}
.matrix li:nth-child(1):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 168px;
  overflow: auto;
  -webkit-animation: 1920ms column-1 infinite steps(1, start);
  -moz-animation: 1920ms column-1 infinite steps(1, start);
  -ms-animation: 1920ms column-1 infinite steps(1, start);
  -o-animation: 1920ms column-1 infinite steps(1, start);
  animation: 1920ms column-1 infinite steps(1, start);
}

@-webkit-keyframes column-1 {
  0% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
}
@-moz-keyframes column-1 {
  0% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
}
@-o-keyframes column-1 {
  0% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
}
@keyframes column-1 {
  0% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
}
.matrix li:nth-child(2) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(2):before {
  display: block;
  width: 1em;
  content: "ラ ワ う ク タ わ バ う ソ ク わ ク マ タ ウ ク た こ う き た シ ト ソ";
}
.matrix li:nth-child(2):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -240px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 -240px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -240px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -240px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -240px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ソ";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-2 infinite steps(1, start);
  -moz-animation: 1920ms column-2 infinite steps(1, start);
  -ms-animation: 1920ms column-2 infinite steps(1, start);
  -o-animation: 1920ms column-2 infinite steps(1, start);
  animation: 1920ms column-2 infinite steps(1, start);
}

@-webkit-keyframes column-2 {
  0% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-2 {
  0% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
}
@-o-keyframes column-2 {
  0% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
}
@keyframes column-2 {
  0% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(3) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(3):before {
  display: block;
  width: 1em;
  content: "ド き ょ ョ と コ し ワ き た ン し ラ ラ ソ バ き ョ ょ バ わ マ ウ た";
}
.matrix li:nth-child(3):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 384px;
  overflow: auto;
  -webkit-animation: 1920ms column-3 infinite steps(1, start);
  -moz-animation: 1920ms column-3 infinite steps(1, start);
  -ms-animation: 1920ms column-3 infinite steps(1, start);
  -o-animation: 1920ms column-3 infinite steps(1, start);
  animation: 1920ms column-3 infinite steps(1, start);
}

@-webkit-keyframes column-3 {
  0% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  4.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  8.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  12.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  16.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  20.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  25% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  29.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  75% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  79.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  83.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  87.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  91.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  95.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
}
@-moz-keyframes column-3 {
  0% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  4.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  8.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  12.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  16.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  20.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  25% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  29.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  75% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  79.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  83.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  87.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  91.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  95.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
}
@-o-keyframes column-3 {
  0% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  4.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  8.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  12.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  16.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  20.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  25% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  29.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  75% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  79.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  83.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  87.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  91.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  95.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
}
@keyframes column-3 {
  0% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  4.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  8.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  12.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  16.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  20.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  25% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  29.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  75% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  79.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  83.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  87.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  91.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  95.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
}
.matrix li:nth-child(4) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(4):before {
  display: block;
  width: 1em;
  content: "ョ ん ン キ ン シ ソ た マ う シ ウ た ょ し ン ク た う バ う ウ ョ と";
}
.matrix li:nth-child(4):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 -24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 -24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 -24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 -24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 96px;
  overflow: auto;
  -webkit-animation: 1920ms column-4 infinite steps(1, start);
  -moz-animation: 1920ms column-4 infinite steps(1, start);
  -ms-animation: 1920ms column-4 infinite steps(1, start);
  -o-animation: 1920ms column-4 infinite steps(1, start);
  animation: 1920ms column-4 infinite steps(1, start);
}

@-webkit-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
}
@-moz-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
}
@-o-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
}
@keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
}
.matrix li:nth-child(5) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(5):before {
  display: block;
  width: 1em;
  content: "タ タ た ん キ ょ マ タ ょ こ し フ き フ き ょ ト ト キ こ シ バ フ う";
}
.matrix li:nth-child(5):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 -96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-5 infinite steps(1, start);
  -moz-animation: 1920ms column-5 infinite steps(1, start);
  -ms-animation: 1920ms column-5 infinite steps(1, start);
  -o-animation: 1920ms column-5 infinite steps(1, start);
  animation: 1920ms column-5 infinite steps(1, start);
}

@-webkit-keyframes column-5 {
  0% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-5 {
  0% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
}
@-o-keyframes column-5 {
  0% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
}
@keyframes column-5 {
  0% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(6) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(6):before {
  display: block;
  width: 1em;
  content: "ょ た ん フ フ ソ ン タ シ ん ョ ド ク ワ ラ し ん た う タ ク タ ン フ";
}
.matrix li:nth-child(6):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 528px;
  overflow: auto;
  -webkit-animation: 1920ms column-6 infinite steps(1, start);
  -moz-animation: 1920ms column-6 infinite steps(1, start);
  -ms-animation: 1920ms column-6 infinite steps(1, start);
  -o-animation: 1920ms column-6 infinite steps(1, start);
  animation: 1920ms column-6 infinite steps(1, start);
}

@-webkit-keyframes column-6 {
  0% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
}
@-moz-keyframes column-6 {
  0% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
}
@-o-keyframes column-6 {
  0% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
}
@keyframes column-6 {
  0% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
}
.matrix li:nth-child(7) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(7):before {
  display: block;
  width: 1em;
  content: "う こ バ コ ト ョ き ン う う う タ ょ コ ン し ソ コ ド ウ ょ ク た ョ";
}
.matrix li:nth-child(7):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-7 infinite steps(1, start);
  -moz-animation: 1920ms column-7 infinite steps(1, start);
  -ms-animation: 1920ms column-7 infinite steps(1, start);
  -o-animation: 1920ms column-7 infinite steps(1, start);
  animation: 1920ms column-7 infinite steps(1, start);
}

@-webkit-keyframes column-7 {
  0% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-7 {
  0% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
}
@-o-keyframes column-7 {
  0% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
}
@keyframes column-7 {
  0% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(8) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(8):before {
  display: block;
  width: 1em;
  content: "ト ソ バ き ょ ウ リ ン わ タ ク う た ょ ド タ こ ョ ん こ ょ ク リ ん";
}
.matrix li:nth-child(8):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(83.33333%, rgba(0, 0, 0, 0)), color-stop(83.33333%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ョ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-8 infinite steps(1, start);
  -moz-animation: 1920ms column-8 infinite steps(1, start);
  -ms-animation: 1920ms column-8 infinite steps(1, start);
  -o-animation: 1920ms column-8 infinite steps(1, start);
  animation: 1920ms column-8 infinite steps(1, start);
}

@-webkit-keyframes column-8 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-8 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@-o-keyframes column-8 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@keyframes column-8 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(9) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(9):before {
  display: block;
  width: 1em;
  content: "ウ マ き ソ ン タ ょ ば タ ト わ と タ ョ ょ ョ う ン リ わ う リ ド バ";
}
.matrix li:nth-child(9):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ト";
  padding-top: 216px;
  overflow: auto;
  -webkit-animation: 1920ms column-9 infinite steps(1, start);
  -moz-animation: 1920ms column-9 infinite steps(1, start);
  -ms-animation: 1920ms column-9 infinite steps(1, start);
  -o-animation: 1920ms column-9 infinite steps(1, start);
  animation: 1920ms column-9 infinite steps(1, start);
}

@-webkit-keyframes column-9 {
  0% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
}
@-moz-keyframes column-9 {
  0% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
}
@-o-keyframes column-9 {
  0% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
}
@keyframes column-9 {
  0% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
}
.matrix li:nth-child(10) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(10):before {
  display: block;
  width: 1em;
  content: "ょ た ン タ リ ソ ウ コ ョ ょ キ う ワ リ ウ ょ ク ョ タ と ョ ン ソ ん";
}
.matrix li:nth-child(10):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(50%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-10 infinite steps(1, start);
  -moz-animation: 1920ms column-10 infinite steps(1, start);
  -ms-animation: 1920ms column-10 infinite steps(1, start);
  -o-animation: 1920ms column-10 infinite steps(1, start);
  animation: 1920ms column-10 infinite steps(1, start);
}

@-webkit-keyframes column-10 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-10 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@-o-keyframes column-10 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@keyframes column-10 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(11) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(11):before {
  display: block;
  width: 1em;
  content: "ょ フ タ ウ ョ ラ ン シ た し ウ バ シ ワ シ う ラ キ ば ド し フ ン う";
}
.matrix li:nth-child(11):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "バ";
  padding-top: 264px;
  overflow: auto;
  -webkit-animation: 1920ms column-11 infinite steps(1, start);
  -moz-animation: 1920ms column-11 infinite steps(1, start);
  -ms-animation: 1920ms column-11 infinite steps(1, start);
  -o-animation: 1920ms column-11 infinite steps(1, start);
  animation: 1920ms column-11 infinite steps(1, start);
}

@-webkit-keyframes column-11 {
  0% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
}
@-moz-keyframes column-11 {
  0% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
}
@-o-keyframes column-11 {
  0% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
}
@keyframes column-11 {
  0% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
}
.matrix li:nth-child(12) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(12):before {
  display: block;
  width: 1em;
  content: "ン シ タ と う ト こ ょ キ ド ウ ン ョ わ ョ コ わ ク リ ソ ョ ラ ン ょ";
}
.matrix li:nth-child(12):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 312px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 312px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-12 infinite steps(1, start);
  -moz-animation: 1920ms column-12 infinite steps(1, start);
  -ms-animation: 1920ms column-12 infinite steps(1, start);
  -o-animation: 1920ms column-12 infinite steps(1, start);
  animation: 1920ms column-12 infinite steps(1, start);
}

@-webkit-keyframes column-12 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-12 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@-o-keyframes column-12 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@keyframes column-12 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(13) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(13):before {
  display: block;
  width: 1em;
  content: "コ ョ き し キ わ ょ バ ク タ ク シ ン ウ ょ ウ シ ョ ょ ド マ わ ウ ょ";
}
.matrix li:nth-child(13):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 240px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(33.33333%, rgba(0, 0, 0, 0)), color-stop(33.33333%, #000000), color-stop(100%, #000000)) 0 240px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 240px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 240px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 240px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-13 infinite steps(1, start);
  -moz-animation: 1920ms column-13 infinite steps(1, start);
  -ms-animation: 1920ms column-13 infinite steps(1, start);
  -o-animation: 1920ms column-13 infinite steps(1, start);
  animation: 1920ms column-13 infinite steps(1, start);
}

@-webkit-keyframes column-13 {
  0% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-13 {
  0% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 408px;
  }
}
@-o-keyframes column-13 {
  0% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 408px;
  }
}
@keyframes column-13 {
  0% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(14) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(14):before {
  display: block;
  width: 1em;
  content: "コ ば ン ば ョ ン う た ト シ マ バ ょ う ょ ワ ウ と ョ こ ク ば う シ";
}
.matrix li:nth-child(14):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 288px;
  overflow: auto;
  -webkit-animation: 1920ms column-14 infinite steps(1, start);
  -moz-animation: 1920ms column-14 infinite steps(1, start);
  -ms-animation: 1920ms column-14 infinite steps(1, start);
  -o-animation: 1920ms column-14 infinite steps(1, start);
  animation: 1920ms column-14 infinite steps(1, start);
}

@-webkit-keyframes column-14 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-moz-keyframes column-14 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-o-keyframes column-14 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@keyframes column-14 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
.matrix li:nth-child(15) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(15):before {
  display: block;
  width: 1em;
  content: "ソ バ バ わ ん ン ょ た と マ ド ョ と と ん た ト た シ わ ョ シ キ タ";
}
.matrix li:nth-child(15):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "わ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-15 infinite steps(1, start);
  -moz-animation: 1920ms column-15 infinite steps(1, start);
  -ms-animation: 1920ms column-15 infinite steps(1, start);
  -o-animation: 1920ms column-15 infinite steps(1, start);
  animation: 1920ms column-15 infinite steps(1, start);
}

@-webkit-keyframes column-15 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-15 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@-o-keyframes column-15 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@keyframes column-15 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(16) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(16):before {
  display: block;
  width: 1em;
  content: "リ タ ト う ド ン ン た ン ょ ょ ク ウ ョ ょ ソ タ ワ バ ょ タ ば と ク";
}
.matrix li:nth-child(16):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(45.83333%, rgba(0, 0, 0, 0)), color-stop(45.83333%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-16 infinite steps(1, start);
  -moz-animation: 1920ms column-16 infinite steps(1, start);
  -ms-animation: 1920ms column-16 infinite steps(1, start);
  -o-animation: 1920ms column-16 infinite steps(1, start);
  animation: 1920ms column-16 infinite steps(1, start);
}

@-webkit-keyframes column-16 {
  0% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-16 {
  0% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 312px;
  }
}
@-o-keyframes column-16 {
  0% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 312px;
  }
}
@keyframes column-16 {
  0% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(17) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(17):before {
  display: block;
  width: 1em;
  content: "ド ょ と ン ン フ ワ ド う う ん ョ キ と ラ ん ラ マ た フ ン ば ン ト";
}
.matrix li:nth-child(17):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-17 infinite steps(1, start);
  -moz-animation: 1920ms column-17 infinite steps(1, start);
  -ms-animation: 1920ms column-17 infinite steps(1, start);
  -o-animation: 1920ms column-17 infinite steps(1, start);
  animation: 1920ms column-17 infinite steps(1, start);
}

@-webkit-keyframes column-17 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-17 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@-o-keyframes column-17 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@keyframes column-17 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(18) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(18):before {
  display: block;
  width: 1em;
  content: "シ た と ウ ト こ バ ば ク う フ ょ し ラ ン き わ ラ リ き ト マ う ョ";
}
.matrix li:nth-child(18):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 312px;
  overflow: auto;
  -webkit-animation: 1920ms column-18 infinite steps(1, start);
  -moz-animation: 1920ms column-18 infinite steps(1, start);
  -ms-animation: 1920ms column-18 infinite steps(1, start);
  -o-animation: 1920ms column-18 infinite steps(1, start);
  animation: 1920ms column-18 infinite steps(1, start);
}

@-webkit-keyframes column-18 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@-moz-keyframes column-18 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@-o-keyframes column-18 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@keyframes column-18 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
.matrix li:nth-child(19) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(19):before {
  display: block;
  width: 1em;
  content: "し ョ タ う コ バ ク う ク ン ん ウ ン タ ょ ソ う ク し シ ょ ウ ョ リ";
}
.matrix li:nth-child(19):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 0px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 0px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 0px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 0px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 0px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 216px;
  overflow: auto;
  -webkit-animation: 1920ms column-19 infinite steps(1, start);
  -moz-animation: 1920ms column-19 infinite steps(1, start);
  -ms-animation: 1920ms column-19 infinite steps(1, start);
  -o-animation: 1920ms column-19 infinite steps(1, start);
  animation: 1920ms column-19 infinite steps(1, start);
}

@-webkit-keyframes column-19 {
  0% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
}
@-moz-keyframes column-19 {
  0% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
}
@-o-keyframes column-19 {
  0% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
}
@keyframes column-19 {
  0% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
}
.matrix li:nth-child(20) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(20):before {
  display: block;
  width: 1em;
  content: "ョ ょ し ク た と ソ し た タ ラ シ キ ょ マ ワ ウ た ウ マ マ ン ょ た";
}
.matrix li:nth-child(20):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 552px;
  overflow: auto;
  -webkit-animation: 1920ms column-20 infinite steps(1, start);
  -moz-animation: 1920ms column-20 infinite steps(1, start);
  -ms-animation: 1920ms column-20 infinite steps(1, start);
  -o-animation: 1920ms column-20 infinite steps(1, start);
  animation: 1920ms column-20 infinite steps(1, start);
}

@-webkit-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
}
@-moz-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
}
@-o-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
}
@keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
}
.matrix li:nth-child(21) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(21):before {
  display: block;
  width: 1em;
  content: "ウ た フ フ わ タ フ ョ タ う ん た ラ ン ク ン ウ リ ワ た ょ た き ド";
}
.matrix li:nth-child(21):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 312px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 312px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 312px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "リ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-21 infinite steps(1, start);
  -moz-animation: 1920ms column-21 infinite steps(1, start);
  -ms-animation: 1920ms column-21 infinite steps(1, start);
  -o-animation: 1920ms column-21 infinite steps(1, start);
  animation: 1920ms column-21 infinite steps(1, start);
}

@-webkit-keyframes column-21 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-21 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@-o-keyframes column-21 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
@keyframes column-21 {
  0% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(22) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(22):before {
  display: block;
  width: 1em;
  content: "う た き ば ラ ク こ こ シ ウ キ ウ ょ フ タ う ト タ ウ ド ょ こ バ リ";
}
.matrix li:nth-child(22):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "シ";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-22 infinite steps(1, start);
  -moz-animation: 1920ms column-22 infinite steps(1, start);
  -ms-animation: 1920ms column-22 infinite steps(1, start);
  -o-animation: 1920ms column-22 infinite steps(1, start);
  animation: 1920ms column-22 infinite steps(1, start);
}

@-webkit-keyframes column-22 {
  0% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-22 {
  0% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
}
@-o-keyframes column-22 {
  0% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
}
@keyframes column-22 {
  0% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(23) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(23):before {
  display: block;
  width: 1em;
  content: "ウ き マ ク ウ タ リ マ う ょ ョ う ワ ラ リ こ タ ん ょ タ ン た ョ ょ";
}
.matrix li:nth-child(23):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(83.33333%, rgba(0, 0, 0, 0)), color-stop(83.33333%, #000000), color-stop(100%, #000000)) 0 -168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 312px;
  overflow: auto;
  -webkit-animation: 1920ms column-23 infinite steps(1, start);
  -moz-animation: 1920ms column-23 infinite steps(1, start);
  -ms-animation: 1920ms column-23 infinite steps(1, start);
  -o-animation: 1920ms column-23 infinite steps(1, start);
  animation: 1920ms column-23 infinite steps(1, start);
}

@-webkit-keyframes column-23 {
  0% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
}
@-moz-keyframes column-23 {
  0% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
}
@-o-keyframes column-23 {
  0% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
}
@keyframes column-23 {
  0% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
}
.matrix li:nth-child(24) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(24):before {
  display: block;
  width: 1em;
  content: "ン と ド た ん う ょ ョ こ リ こ リ こ ウ わ ん ク マ タ ん と し ト フ";
}
.matrix li:nth-child(24):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "こ";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-24 infinite steps(1, start);
  -moz-animation: 1920ms column-24 infinite steps(1, start);
  -ms-animation: 1920ms column-24 infinite steps(1, start);
  -o-animation: 1920ms column-24 infinite steps(1, start);
  animation: 1920ms column-24 infinite steps(1, start);
}

@-webkit-keyframes column-24 {
  0% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-24 {
  0% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
}
@-o-keyframes column-24 {
  0% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
}
@keyframes column-24 {
  0% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(25) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(25):before {
  display: block;
  width: 1em;
  content: "ウ タ ラ ょ タ こ シ ト ょ コ シ ト タ ド と ば ょ う ョ ワ ョ わ バ ン";
}
.matrix li:nth-child(25):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-25 infinite steps(1, start);
  -moz-animation: 1920ms column-25 infinite steps(1, start);
  -ms-animation: 1920ms column-25 infinite steps(1, start);
  -o-animation: 1920ms column-25 infinite steps(1, start);
  animation: 1920ms column-25 infinite steps(1, start);
}

@-webkit-keyframes column-25 {
  0% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-25 {
  0% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
}
@-o-keyframes column-25 {
  0% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
}
@keyframes column-25 {
  0% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(26) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(26):before {
  display: block;
  width: 1em;
  content: "う キ と た ド ラ う タ タ キ ョ た ト ウ ト ウ フ う き タ と ん タ バ";
}
.matrix li:nth-child(26):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -480px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -480px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -480px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -480px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -480px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "キ";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-26 infinite steps(1, start);
  -moz-animation: 1920ms column-26 infinite steps(1, start);
  -ms-animation: 1920ms column-26 infinite steps(1, start);
  -o-animation: 1920ms column-26 infinite steps(1, start);
  animation: 1920ms column-26 infinite steps(1, start);
}

@-webkit-keyframes column-26 {
  0% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-26 {
  0% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
}
@-o-keyframes column-26 {
  0% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
}
@keyframes column-26 {
  0% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(27) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(27):before {
  display: block;
  width: 1em;
  content: "コ ョ ド ョ ソ し ワ と ウ わ ょ ラ ド フ わ わ ン と た ウ ウ ト ょ ョ";
}
.matrix li:nth-child(27):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 0px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 0px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 0px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 0px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 0px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-27 infinite steps(1, start);
  -moz-animation: 1920ms column-27 infinite steps(1, start);
  -ms-animation: 1920ms column-27 infinite steps(1, start);
  -o-animation: 1920ms column-27 infinite steps(1, start);
  animation: 1920ms column-27 infinite steps(1, start);
}

@-webkit-keyframes column-27 {
  0% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-27 {
  0% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
}
@-o-keyframes column-27 {
  0% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
}
@keyframes column-27 {
  0% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(28) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(28):before {
  display: block;
  width: 1em;
  content: "ト た う ば わ ク ん ソ ウ こ ょ た ョ リ ょ バ ウ ン わ シ ト う と う";
}
.matrix li:nth-child(28):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-28 infinite steps(1, start);
  -moz-animation: 1920ms column-28 infinite steps(1, start);
  -ms-animation: 1920ms column-28 infinite steps(1, start);
  -o-animation: 1920ms column-28 infinite steps(1, start);
  animation: 1920ms column-28 infinite steps(1, start);
}

@-webkit-keyframes column-28 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-28 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@-o-keyframes column-28 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@keyframes column-28 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(29) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(29):before {
  display: block;
  width: 1em;
  content: "わ し ょ き タ ソ ソ こ ワ た た た ワ マ ウ ょ ド ト リ ウ タ ド ョ マ";
}
.matrix li:nth-child(29):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ド";
  padding-top: 504px;
  overflow: auto;
  -webkit-animation: 1920ms column-29 infinite steps(1, start);
  -moz-animation: 1920ms column-29 infinite steps(1, start);
  -ms-animation: 1920ms column-29 infinite steps(1, start);
  -o-animation: 1920ms column-29 infinite steps(1, start);
  animation: 1920ms column-29 infinite steps(1, start);
}

@-webkit-keyframes column-29 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@-moz-keyframes column-29 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@-o-keyframes column-29 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@keyframes column-29 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
.matrix li:nth-child(30) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(30):before {
  display: block;
  width: 1em;
  content: "フ ド ば ん ン ン た タ ョ ク と タ ン ウ ラ こ コ コ ン ん バ タ マ シ";
}
.matrix li:nth-child(30):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ば";
  padding-top: 48px;
  overflow: auto;
  -webkit-animation: 1920ms column-30 infinite steps(1, start);
  -moz-animation: 1920ms column-30 infinite steps(1, start);
  -ms-animation: 1920ms column-30 infinite steps(1, start);
  -o-animation: 1920ms column-30 infinite steps(1, start);
  animation: 1920ms column-30 infinite steps(1, start);
}

@-webkit-keyframes column-30 {
  0% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
}
@-moz-keyframes column-30 {
  0% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
}
@-o-keyframes column-30 {
  0% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
}
@keyframes column-30 {
  0% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
}
.matrix li:nth-child(31) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(31):before {
  display: block;
  width: 1em;
  content: "わ ば ラ ょ ょ バ た ウ ト コ し ば ょ ワ ウ ン し う ン タ タ シ リ キ";
}
.matrix li:nth-child(31):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-31 infinite steps(1, start);
  -moz-animation: 1920ms column-31 infinite steps(1, start);
  -ms-animation: 1920ms column-31 infinite steps(1, start);
  -o-animation: 1920ms column-31 infinite steps(1, start);
  animation: 1920ms column-31 infinite steps(1, start);
}

@-webkit-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@-o-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
@keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(32) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(32):before {
  display: block;
  width: 1em;
  content: "ワ ン マ と ょ ょ ド う リ し マ コ き ウ と ば ょ ば ト き ラ ョ う ょ";
}
.matrix li:nth-child(32):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 480px;
  overflow: auto;
  -webkit-animation: 1920ms column-32 infinite steps(1, start);
  -moz-animation: 1920ms column-32 infinite steps(1, start);
  -ms-animation: 1920ms column-32 infinite steps(1, start);
  -o-animation: 1920ms column-32 infinite steps(1, start);
  animation: 1920ms column-32 infinite steps(1, start);
}

@-webkit-keyframes column-32 {
  0% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
}
@-moz-keyframes column-32 {
  0% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
}
@-o-keyframes column-32 {
  0% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
}
@keyframes column-32 {
  0% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
}

