.page1-container {
    padding: 1px;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Space Mono", monospace;

  }
  
  h1 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .service {
    background: rgba(0, 0, 0, 0.5); /* Added opacity */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    
  }
  
  .service h2 {
    color: #007BFF;
    margin-bottom: 10px;
  }
  
  .service p {
    color: #ffffff;
  }
  