.about-us-section {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  max-width: 500px;
  }
  
  p {
  line-height: 2;
  color: white;
  }
  h2{
  color:white;
  margin-bottom: 20px;
  }