.container-hacked-subheading {
  /* padding-top: x; */
  cursor: pointer;
}
a {
  text-decoration: none;
}
/* Green version */
.hacked-subheading-green h2 {
  color: #00ff00;
  font-family: "Space Mono", monospace;
  font-size: clamp(2rem, 5vw, 5rem);

  padding: 0; /* updated padding */
  border-radius: clamp(0.4rem, 0.75vw, 1rem);
}

.hacked-subheading-green h2:hover {
  background-color: #00ff00;
  color: black;
}

/* White version */
.hacked-subheading-white h2 {
  color: white;
  font-family: 'Space Mono', monospace;
  font-size: clamp(2rem, 5vw, 5rem);

  padding: 0; /* updated padding */
  border-radius: clamp(0.4rem, 0.75vw, 1rem);
}

.hacked-subheading-white h2:hover {
  background-color: white;
  color: black;
}
