/* body {
	--pos: 65%;
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	perspective: 100vmin;
	background: radial-gradient(circle at 50% -25%, #000000, #0e4964);
}
 */

 body * {
	transform-style: preserve-3d;
}

.content {
	width: 35vmin;
	height: 30.5vmin;
    transition: all 1s ease 0s;
	transform: translate(0px, -5vmin); 
	animation: floating 6s ease-in-out 0s infinite;
	cursor: pointer;
}

.content:before {
    content: "";
    position: absolute;
    width: 33%;
    height: 33%;
    left: 33%;
    top: 33%;
    transform: rotateX(60deg) translateZ(-40vmin);
    border-radius: 100%;
    box-shadow: 0 0 5vmin 0 #0000006b, 0 0 10vmin 0 #0000006b inset;
    filter: blur(10px);
    opacity: 0.25;
}

.content:hover, .content:hover * { animation-play-state:paused; }
.content:active .spin { animation: crazy 3s linear 0s infinite; }



@keyframes crazy {
	100% { transform: rotateY(360deg) rotateX(360deg); }
}

@keyframes floating {
	50% { transform: translate(0px, 10vmin); }
}


	
.pyramid {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg);
}

.pyramid span {
	background: #343434;
	position: absolute;
	width: 100%;
	height: 100%;
	clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    --clr: #0000003b;
	--shadow: 
		repeating-conic-gradient(#0003 0.000001%, #fff0 .00005%, #fff0 .00035%, #fff0 .00005%),  
		repeating-conic-gradient(#fff2 0.00002%, #fff0 .00008%, #fff0 .0008%, #fff0 .00008%), 
		radial-gradient(circle at 50% 37%, #fff -25%, var(--clr) 45%),   
		linear-gradient(180deg, var(--clr) 0 0%, transparent 3%),  
		linear-gradient(-60deg, var(--clr) 0 33%, transparent 36%),   
		linear-gradient(60deg, var(--clr) 0 33%, transparent 36%);
}	

.pyramid span:nth-child(2) {
    transform: rotateX(70.7deg);
    transform-origin: 50% 0;
    background: var(--shadow), #e1e1e1;
}

.pyramid span:nth-child(3) {
    transform: rotateZ(60deg) rotateY(0deg) rotateX(109.35deg);
    transform-origin: 0% 0%;
    background: var(--shadow), #8b898b;
}

.pyramid span:nth-child(4) {
    background: var(--shadow), #646464;
    transform: rotateZ(-60deg) rotateY(0deg) rotateX(109.35deg);
    transform-origin: 100% 0%;
}


.pyramid + .pyramid {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateZ(180deg) rotateX(-70deg);
}

.pyramid + .pyramid span:nth-child(2) {
    --clr: #0000003b;
    background: var(--shadow), #c3c3c3;
}


.spin {
	position: absolute;
	width: 100%;
	height: 100%;
	animation: spining 15s ease-in-out 0s infinite;
}

@keyframes spining {
	0%, 100% { transform: rotateY(-15deg); }
	50% { transform: rotateY(15deg); }
}