@charset "UTF-8";
body {
  font-family: courier;
  background: black;
  overflow: auto;
  margin: 0;
  padding: 0;
  text-align: center;
}

.matrix-container {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

.matrix-desktop {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #80ff80;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.matrix li:nth-child(1) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(1):before {
  display: block;
  width: 1em;
  content: "ト ョ ワ ョ シ う き ば ク ワ ょ ウ ば ョ と ん ン ょ ョ ョ ク ラ ド マ";
}
.matrix li:nth-child(1):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-1 infinite steps(1, start);
  -moz-animation: 1920ms column-1 infinite steps(1, start);
  -ms-animation: 1920ms column-1 infinite steps(1, start);
  -o-animation: 1920ms column-1 infinite steps(1, start);
  animation: 1920ms column-1 infinite steps(1, start);
}

@-webkit-keyframes column-1 {
  0% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-1 {
  0% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
}
@-o-keyframes column-1 {
  0% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
}
@keyframes column-1 {
  0% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(2) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(2):before {
  display: block;
  width: 1em;
  content: "フ キ ば ト フ コ ト ウ き ド ト と ラ フ う ョ ウ ょ ン う リ シ う う";
}
.matrix li:nth-child(2):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "フ";
  padding-top: 312px;
  overflow: auto;
  -webkit-animation: 1920ms column-2 infinite steps(1, start);
  -moz-animation: 1920ms column-2 infinite steps(1, start);
  -ms-animation: 1920ms column-2 infinite steps(1, start);
  -o-animation: 1920ms column-2 infinite steps(1, start);
  animation: 1920ms column-2 infinite steps(1, start);
}

@-webkit-keyframes column-2 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@-moz-keyframes column-2 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@-o-keyframes column-2 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
@keyframes column-2 {
  0% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
}
.matrix li:nth-child(3) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(3):before {
  display: block;
  width: 1em;
  content: "ば ウ ト た と フ ウ ク ク し た わ ョ リ ん ラ ド ョ ワ ド ク ソ ク う";
}
.matrix li:nth-child(3):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(41.66667%, rgba(0, 0, 0, 0)), color-stop(41.66667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "リ";
  padding-top: 312px;
  overflow: auto;
  -webkit-animation: 1920ms column-3 infinite steps(1, start);
  -moz-animation: 1920ms column-3 infinite steps(1, start);
  -ms-animation: 1920ms column-3 infinite steps(1, start);
  -o-animation: 1920ms column-3 infinite steps(1, start);
  animation: 1920ms column-3 infinite steps(1, start);
}

@-webkit-keyframes column-3 {
  0% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 288px;
  }
}
@-moz-keyframes column-3 {
  0% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 288px;
  }
}
@-o-keyframes column-3 {
  0% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 288px;
  }
}
@keyframes column-3 {
  0% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 288px;
  }
}
.matrix li:nth-child(4) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(4):before {
  display: block;
  width: 1em;
  content: "と ワ ョ ウ わ シ リ ョ ょ ン ト た ョ き タ た き ン し ン ょ ワ マ ウ";
}
.matrix li:nth-child(4):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 -24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-4 infinite steps(1, start);
  -moz-animation: 1920ms column-4 infinite steps(1, start);
  -ms-animation: 1920ms column-4 infinite steps(1, start);
  -o-animation: 1920ms column-4 infinite steps(1, start);
  animation: 1920ms column-4 infinite steps(1, start);
}

@-webkit-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
}
@-o-keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
}
@keyframes column-4 {
  0% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(5) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(5):before {
  display: block;
  width: 1em;
  content: "し ョ と ん マ と ョ キ リ き ド ば ょ ば ソ バ ソ ワ ン ワ た し ョ き";
}
.matrix li:nth-child(5):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 552px;
  overflow: auto;
  -webkit-animation: 1920ms column-5 infinite steps(1, start);
  -moz-animation: 1920ms column-5 infinite steps(1, start);
  -ms-animation: 1920ms column-5 infinite steps(1, start);
  -o-animation: 1920ms column-5 infinite steps(1, start);
  animation: 1920ms column-5 infinite steps(1, start);
}

@-webkit-keyframes column-5 {
  0% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
}
@-moz-keyframes column-5 {
  0% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
}
@-o-keyframes column-5 {
  0% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
}
@keyframes column-5 {
  0% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
}
.matrix li:nth-child(6) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(6):before {
  display: block;
  width: 1em;
  content: "ば た こ ょ う ン キ ウ ウ フ ン た た フ ラ う バ ョ マ た ウ ド ウ マ";
}
.matrix li:nth-child(6):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "フ";
  padding-top: 216px;
  overflow: auto;
  -webkit-animation: 1920ms column-6 infinite steps(1, start);
  -moz-animation: 1920ms column-6 infinite steps(1, start);
  -ms-animation: 1920ms column-6 infinite steps(1, start);
  -o-animation: 1920ms column-6 infinite steps(1, start);
  animation: 1920ms column-6 infinite steps(1, start);
}

@-webkit-keyframes column-6 {
  0% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
}
@-moz-keyframes column-6 {
  0% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
}
@-o-keyframes column-6 {
  0% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
}
@keyframes column-6 {
  0% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
}
.matrix li:nth-child(7) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(7):before {
  display: block;
  width: 1em;
  content: "き リ と マ き ョ た ン コ わ バ シ ラ こ ョ ウ ワ フ う こ ウ リ マ リ";
}
.matrix li:nth-child(7):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-7 infinite steps(1, start);
  -moz-animation: 1920ms column-7 infinite steps(1, start);
  -ms-animation: 1920ms column-7 infinite steps(1, start);
  -o-animation: 1920ms column-7 infinite steps(1, start);
  animation: 1920ms column-7 infinite steps(1, start);
}

@-webkit-keyframes column-7 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-7 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@-o-keyframes column-7 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@keyframes column-7 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(8) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(8):before {
  display: block;
  width: 1em;
  content: "こ ウ ン ク ョ コ た き と こ マ ョ ン ょ フ ド ワ た ワ タ リ き マ ょ";
}
.matrix li:nth-child(8):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 264px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 264px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-8 infinite steps(1, start);
  -moz-animation: 1920ms column-8 infinite steps(1, start);
  -ms-animation: 1920ms column-8 infinite steps(1, start);
  -o-animation: 1920ms column-8 infinite steps(1, start);
  animation: 1920ms column-8 infinite steps(1, start);
}

@-webkit-keyframes column-8 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-8 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@-o-keyframes column-8 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@keyframes column-8 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(9) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(9):before {
  display: block;
  width: 1em;
  content: "し ば た ょ タ た わ う た キ ト ラ ョ し リ た ウ ド バ た た フ ラ ク";
}
.matrix li:nth-child(9):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 168px;
  overflow: auto;
  -webkit-animation: 1920ms column-9 infinite steps(1, start);
  -moz-animation: 1920ms column-9 infinite steps(1, start);
  -ms-animation: 1920ms column-9 infinite steps(1, start);
  -o-animation: 1920ms column-9 infinite steps(1, start);
  animation: 1920ms column-9 infinite steps(1, start);
}

@-webkit-keyframes column-9 {
  0% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
}
@-moz-keyframes column-9 {
  0% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
}
@-o-keyframes column-9 {
  0% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
}
@keyframes column-9 {
  0% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
}
.matrix li:nth-child(10) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(10):before {
  display: block;
  width: 1em;
  content: "コ し ょ ん ょ た ょ き ょ バ キ リ ウ ン キ ド バ ト バ う ょ キ た コ";
}
.matrix li:nth-child(10):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 96px;
  overflow: auto;
  -webkit-animation: 1920ms column-10 infinite steps(1, start);
  -moz-animation: 1920ms column-10 infinite steps(1, start);
  -ms-animation: 1920ms column-10 infinite steps(1, start);
  -o-animation: 1920ms column-10 infinite steps(1, start);
  animation: 1920ms column-10 infinite steps(1, start);
}

@-webkit-keyframes column-10 {
  0% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
}
@-moz-keyframes column-10 {
  0% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
}
@-o-keyframes column-10 {
  0% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
}
@keyframes column-10 {
  0% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
}
.matrix li:nth-child(11) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(11):before {
  display: block;
  width: 1em;
  content: "ク バ と タ ト き タ ウ ク タ ウ ワ き き タ う し ょ ョ ば ば ク ウ ト";
}
.matrix li:nth-child(11):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(83.33333%, rgba(0, 0, 0, 0)), color-stop(83.33333%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-11 infinite steps(1, start);
  -moz-animation: 1920ms column-11 infinite steps(1, start);
  -ms-animation: 1920ms column-11 infinite steps(1, start);
  -o-animation: 1920ms column-11 infinite steps(1, start);
  animation: 1920ms column-11 infinite steps(1, start);
}

@-webkit-keyframes column-11 {
  0% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-11 {
  0% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
}
@-o-keyframes column-11 {
  0% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
}
@keyframes column-11 {
  0% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(12) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(12):before {
  display: block;
  width: 1em;
  content: "き ソ た ク リ ョ ト う う し バ ょ た う ン ウ コ ん ワ た タ ド こ ド";
}
.matrix li:nth-child(12):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-12 infinite steps(1, start);
  -moz-animation: 1920ms column-12 infinite steps(1, start);
  -ms-animation: 1920ms column-12 infinite steps(1, start);
  -o-animation: 1920ms column-12 infinite steps(1, start);
  animation: 1920ms column-12 infinite steps(1, start);
}

@-webkit-keyframes column-12 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-12 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@-o-keyframes column-12 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@keyframes column-12 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(13) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(13):before {
  display: block;
  width: 1em;
  content: "ん ソ た わ ョ ン ん と マ シ わ ウ ク ワ バ ン ウ ょ ラ タ リ し ば リ";
}
.matrix li:nth-child(13):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-13 infinite steps(1, start);
  -moz-animation: 1920ms column-13 infinite steps(1, start);
  -ms-animation: 1920ms column-13 infinite steps(1, start);
  -o-animation: 1920ms column-13 infinite steps(1, start);
  animation: 1920ms column-13 infinite steps(1, start);
}

@-webkit-keyframes column-13 {
  0% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-13 {
  0% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
}
@-o-keyframes column-13 {
  0% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
}
@keyframes column-13 {
  0% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(14) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(14):before {
  display: block;
  width: 1em;
  content: "ョ た マ ド ラ ソ フ ク わ タ ソ マ タ ラ ウ キ タ リ ク う ン ょ ょ ば";
}
.matrix li:nth-child(14):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ソ";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-14 infinite steps(1, start);
  -moz-animation: 1920ms column-14 infinite steps(1, start);
  -ms-animation: 1920ms column-14 infinite steps(1, start);
  -o-animation: 1920ms column-14 infinite steps(1, start);
  animation: 1920ms column-14 infinite steps(1, start);
}

@-webkit-keyframes column-14 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-14 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-o-keyframes column-14 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@keyframes column-14 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(15) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(15):before {
  display: block;
  width: 1em;
  content: "た ク キ し タ バ ク ワ ソ タ マ ょ タ ド タ キ コ ョ ン タ ワ と タ た";
}
.matrix li:nth-child(15):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -504px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -504px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -504px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -504px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -504px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-15 infinite steps(1, start);
  -moz-animation: 1920ms column-15 infinite steps(1, start);
  -ms-animation: 1920ms column-15 infinite steps(1, start);
  -o-animation: 1920ms column-15 infinite steps(1, start);
  animation: 1920ms column-15 infinite steps(1, start);
}

@-webkit-keyframes column-15 {
  0% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-15 {
  0% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
}
@-o-keyframes column-15 {
  0% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
}
@keyframes column-15 {
  0% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(16) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(16):before {
  display: block;
  width: 1em;
  content: "ン ラ ョ マ ウ わ と ょ リ わ こ タ タ リ ば ョ た こ ド し ド ょ タ マ";
}
.matrix li:nth-child(16):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -264px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -264px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -264px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -264px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -264px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 96px;
  overflow: auto;
  -webkit-animation: 1920ms column-16 infinite steps(1, start);
  -moz-animation: 1920ms column-16 infinite steps(1, start);
  -ms-animation: 1920ms column-16 infinite steps(1, start);
  -o-animation: 1920ms column-16 infinite steps(1, start);
  animation: 1920ms column-16 infinite steps(1, start);
}

@-webkit-keyframes column-16 {
  0% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
}
@-moz-keyframes column-16 {
  0% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
}
@-o-keyframes column-16 {
  0% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
}
@keyframes column-16 {
  0% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
}
.matrix li:nth-child(17) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(17):before {
  display: block;
  width: 1em;
  content: "コ う ョ こ し と わ こ ょ リ う バ き ョ ょ ン ト ン う こ し ば ド ワ";
}
.matrix li:nth-child(17):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(54.16667%, rgba(0, 0, 0, 0)), color-stop(54.16667%, #000000), color-stop(100%, #000000)) 0 -168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "わ";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-17 infinite steps(1, start);
  -moz-animation: 1920ms column-17 infinite steps(1, start);
  -ms-animation: 1920ms column-17 infinite steps(1, start);
  -o-animation: 1920ms column-17 infinite steps(1, start);
  animation: 1920ms column-17 infinite steps(1, start);
}

@-webkit-keyframes column-17 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-17 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@-o-keyframes column-17 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@keyframes column-17 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(18) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(18):before {
  display: block;
  width: 1em;
  content: "ウ コ ワ ト ン キ ド ょ シ ン ワ タ ワ シ バ ラ フ ょ コ マ し う こ マ";
}
.matrix li:nth-child(18):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ワ";
  padding-top: 48px;
  overflow: auto;
  -webkit-animation: 1920ms column-18 infinite steps(1, start);
  -moz-animation: 1920ms column-18 infinite steps(1, start);
  -ms-animation: 1920ms column-18 infinite steps(1, start);
  -o-animation: 1920ms column-18 infinite steps(1, start);
  animation: 1920ms column-18 infinite steps(1, start);
}

@-webkit-keyframes column-18 {
  0% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
}
@-moz-keyframes column-18 {
  0% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
}
@-o-keyframes column-18 {
  0% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
}
@keyframes column-18 {
  0% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
}
.matrix li:nth-child(19) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(19):before {
  display: block;
  width: 1em;
  content: "う た ト リ バ ん う わ ト マ フ ト ド ン ょ ょ タ き リ シ タ た う フ";
}
.matrix li:nth-child(19):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ん";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-19 infinite steps(1, start);
  -moz-animation: 1920ms column-19 infinite steps(1, start);
  -ms-animation: 1920ms column-19 infinite steps(1, start);
  -o-animation: 1920ms column-19 infinite steps(1, start);
  animation: 1920ms column-19 infinite steps(1, start);
}

@-webkit-keyframes column-19 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-19 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@-o-keyframes column-19 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@keyframes column-19 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(20) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(20):before {
  display: block;
  width: 1em;
  content: "ウ し ウ ょ ン ョ ん シ ウ タ し ん ば マ ソ た バ ょ タ マ き ウ ン ウ";
}
.matrix li:nth-child(20):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ョ";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-20 infinite steps(1, start);
  -moz-animation: 1920ms column-20 infinite steps(1, start);
  -ms-animation: 1920ms column-20 infinite steps(1, start);
  -o-animation: 1920ms column-20 infinite steps(1, start);
  animation: 1920ms column-20 infinite steps(1, start);
}

@-webkit-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-o-keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@keyframes column-20 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(21) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(21):before {
  display: block;
  width: 1em;
  content: "ン タ と タ ん ト シ ク ク ば し う ド ン リ コ リ ば う リ た ょ タ う";
}
.matrix li:nth-child(21):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 240px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 240px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 240px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 240px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 240px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "リ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-21 infinite steps(1, start);
  -moz-animation: 1920ms column-21 infinite steps(1, start);
  -ms-animation: 1920ms column-21 infinite steps(1, start);
  -o-animation: 1920ms column-21 infinite steps(1, start);
  animation: 1920ms column-21 infinite steps(1, start);
}

@-webkit-keyframes column-21 {
  0% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-21 {
  0% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
}
@-o-keyframes column-21 {
  0% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
}
@keyframes column-21 {
  0% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(22) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(22):before {
  display: block;
  width: 1em;
  content: "こ マ フ ょ ウ た ラ フ フ ば ン ラ フ こ た た ば わ シ ん こ ト ド フ";
}
.matrix li:nth-child(22):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 264px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 264px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 264px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 264px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 264px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-22 infinite steps(1, start);
  -moz-animation: 1920ms column-22 infinite steps(1, start);
  -ms-animation: 1920ms column-22 infinite steps(1, start);
  -o-animation: 1920ms column-22 infinite steps(1, start);
  animation: 1920ms column-22 infinite steps(1, start);
}

@-webkit-keyframes column-22 {
  0% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-22 {
  0% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
}
@-o-keyframes column-22 {
  0% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
}
@keyframes column-22 {
  0% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(23) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(23):before {
  display: block;
  width: 1em;
  content: "ば う ょ う タ こ マ ン フ マ ラ ば コ ョ ト マ ョ ク た し ト フ ラ ウ";
}
.matrix li:nth-child(23):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-23 infinite steps(1, start);
  -moz-animation: 1920ms column-23 infinite steps(1, start);
  -ms-animation: 1920ms column-23 infinite steps(1, start);
  -o-animation: 1920ms column-23 infinite steps(1, start);
  animation: 1920ms column-23 infinite steps(1, start);
}

@-webkit-keyframes column-23 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-23 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@-o-keyframes column-23 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@keyframes column-23 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(24) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(24):before {
  display: block;
  width: 1em;
  content: "ワ う キ ラ ョ こ ョ ん リ バ ん ン ソ と キ フ ウ き タ わ バ う ば タ";
}
.matrix li:nth-child(24):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ん";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-24 infinite steps(1, start);
  -moz-animation: 1920ms column-24 infinite steps(1, start);
  -ms-animation: 1920ms column-24 infinite steps(1, start);
  -o-animation: 1920ms column-24 infinite steps(1, start);
  animation: 1920ms column-24 infinite steps(1, start);
}

@-webkit-keyframes column-24 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-24 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@-o-keyframes column-24 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@keyframes column-24 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(25) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(25):before {
  display: block;
  width: 1em;
  content: "シ わ た ソ ば わ リ し し ト ん ウ ン ョ し コ フ ば キ こ ョ ラ タ ソ";
}
.matrix li:nth-child(25):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ん";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-25 infinite steps(1, start);
  -moz-animation: 1920ms column-25 infinite steps(1, start);
  -ms-animation: 1920ms column-25 infinite steps(1, start);
  -o-animation: 1920ms column-25 infinite steps(1, start);
  animation: 1920ms column-25 infinite steps(1, start);
}

@-webkit-keyframes column-25 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-25 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@-o-keyframes column-25 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
@keyframes column-25 {
  0% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(26) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(26):before {
  display: block;
  width: 1em;
  content: "た バ キ ラ き タ と う バ き う フ う ョ シ マ キ ン た ワ フ ド ウ フ";
}
.matrix li:nth-child(26):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "と";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-26 infinite steps(1, start);
  -moz-animation: 1920ms column-26 infinite steps(1, start);
  -ms-animation: 1920ms column-26 infinite steps(1, start);
  -o-animation: 1920ms column-26 infinite steps(1, start);
  animation: 1920ms column-26 infinite steps(1, start);
}

@-webkit-keyframes column-26 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-26 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@-o-keyframes column-26 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@keyframes column-26 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(27) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(27):before {
  display: block;
  width: 1em;
  content: "し ド フ た と ク こ ば ば ワ ょ マ ラ こ コ し コ ソ た バ ソ ョ ウ バ";
}
.matrix li:nth-child(27):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -360px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -360px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -360px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -360px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -360px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "フ";
  padding-top: 48px;
  overflow: auto;
  -webkit-animation: 1920ms column-27 infinite steps(1, start);
  -moz-animation: 1920ms column-27 infinite steps(1, start);
  -ms-animation: 1920ms column-27 infinite steps(1, start);
  -o-animation: 1920ms column-27 infinite steps(1, start);
  animation: 1920ms column-27 infinite steps(1, start);
}

@-webkit-keyframes column-27 {
  0% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
}
@-moz-keyframes column-27 {
  0% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
}
@-o-keyframes column-27 {
  0% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
}
@keyframes column-27 {
  0% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
}
.matrix li:nth-child(28) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(28):before {
  display: block;
  width: 1em;
  content: "ワ ン う こ ン こ ョ バ う う ン マ リ た ょ ト ト ょ う こ ン き シ た";
}
.matrix li:nth-child(28):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 384px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 384px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 384px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 384px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 384px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 480px;
  overflow: auto;
  -webkit-animation: 1920ms column-28 infinite steps(1, start);
  -moz-animation: 1920ms column-28 infinite steps(1, start);
  -ms-animation: 1920ms column-28 infinite steps(1, start);
  -o-animation: 1920ms column-28 infinite steps(1, start);
  animation: 1920ms column-28 infinite steps(1, start);
}

@-webkit-keyframes column-28 {
  0% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
}
@-moz-keyframes column-28 {
  0% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
}
@-o-keyframes column-28 {
  0% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
}
@keyframes column-28 {
  0% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  4.16667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  8.33333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  12.5% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  91.66667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  95.83333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
}
.matrix li:nth-child(29) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(29):before {
  display: block;
  width: 1em;
  content: "ン ん ソ タ ョ ん わ タ わ た タ ン わ ラ う ョ バ ト ソ ド き ク リ と";
}
.matrix li:nth-child(29):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(83.33333%, rgba(0, 0, 0, 0)), color-stop(83.33333%, #000000), color-stop(100%, #000000)) 0 -24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ド";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-29 infinite steps(1, start);
  -moz-animation: 1920ms column-29 infinite steps(1, start);
  -ms-animation: 1920ms column-29 infinite steps(1, start);
  -o-animation: 1920ms column-29 infinite steps(1, start);
  animation: 1920ms column-29 infinite steps(1, start);
}

@-webkit-keyframes column-29 {
  0% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-29 {
  0% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
}
@-o-keyframes column-29 {
  0% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
}
@keyframes column-29 {
  0% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(30) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(30):before {
  display: block;
  width: 1em;
  content: "ウ シ ワ し わ キ た シ ン ソ き と う ソ ょ う リ と ょ ト こ ウ シ ク";
}
.matrix li:nth-child(30):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ト";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-30 infinite steps(1, start);
  -moz-animation: 1920ms column-30 infinite steps(1, start);
  -ms-animation: 1920ms column-30 infinite steps(1, start);
  -o-animation: 1920ms column-30 infinite steps(1, start);
  animation: 1920ms column-30 infinite steps(1, start);
}

@-webkit-keyframes column-30 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-30 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@-o-keyframes column-30 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@keyframes column-30 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(31) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(31):before {
  display: block;
  width: 1em;
  content: "ワ フ た う タ ば バ キ ン き ば シ ラ し ョ シ ト シ ワ ラ ラ シ ト ョ";
}
.matrix li:nth-child(31):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ト";
  padding-top: 528px;
  overflow: auto;
  -webkit-animation: 1920ms column-31 infinite steps(1, start);
  -moz-animation: 1920ms column-31 infinite steps(1, start);
  -ms-animation: 1920ms column-31 infinite steps(1, start);
  -o-animation: 1920ms column-31 infinite steps(1, start);
  animation: 1920ms column-31 infinite steps(1, start);
}

@-webkit-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
}
@-moz-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
}
@-o-keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
}
@keyframes column-31 {
  0% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
}
.matrix li:nth-child(32) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(32):before {
  display: block;
  width: 1em;
  content: "ド た ン ン ド わ キ タ ワ ラ う わ ン コ ト ン リ シ タ キ コ コ ソ き";
}
.matrix li:nth-child(32):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 552px;
  overflow: auto;
  -webkit-animation: 1920ms column-32 infinite steps(1, start);
  -moz-animation: 1920ms column-32 infinite steps(1, start);
  -ms-animation: 1920ms column-32 infinite steps(1, start);
  -o-animation: 1920ms column-32 infinite steps(1, start);
  animation: 1920ms column-32 infinite steps(1, start);
}

@-webkit-keyframes column-32 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@-moz-keyframes column-32 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@-o-keyframes column-32 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@keyframes column-32 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
.matrix li:nth-child(33) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(33):before {
  display: block;
  width: 1em;
  content: "た と コ コ キ キ ん ウ バ ト コ バ リ う ン タ フ ト ド ド ン タ ウ コ";
}
.matrix li:nth-child(33):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(45.83333%, rgba(0, 0, 0, 0)), color-stop(45.83333%, #000000), color-stop(100%, #000000)) 0 -120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 -120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 -120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 264px, #000000 264px, #000000 576px) 0 -120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ん";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-33 infinite steps(1, start);
  -moz-animation: 1920ms column-33 infinite steps(1, start);
  -ms-animation: 1920ms column-33 infinite steps(1, start);
  -o-animation: 1920ms column-33 infinite steps(1, start);
  animation: 1920ms column-33 infinite steps(1, start);
}

@-webkit-keyframes column-33 {
  0% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-33 {
  0% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
}
@-o-keyframes column-33 {
  0% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
}
@keyframes column-33 {
  0% {
    background-position: 0 -120px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -264px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -240px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(34) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(34):before {
  display: block;
  width: 1em;
  content: "ト バ た ョ ウ リ う た う シ と ワ リ と た ょ リ ラ た キ ウ ン ン ん";
}
.matrix li:nth-child(34):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -264px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 -264px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -264px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -264px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 -264px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "リ";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-34 infinite steps(1, start);
  -moz-animation: 1920ms column-34 infinite steps(1, start);
  -ms-animation: 1920ms column-34 infinite steps(1, start);
  -o-animation: 1920ms column-34 infinite steps(1, start);
  animation: 1920ms column-34 infinite steps(1, start);
}

@-webkit-keyframes column-34 {
  0% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-34 {
  0% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
}
@-o-keyframes column-34 {
  0% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
}
@keyframes column-34 {
  0% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(35) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(35):before {
  display: block;
  width: 1em;
  content: "た ン ン ド タ ウ わ と ワ ョ マ ク ン ト リ ク マ タ ょ う タ ょ シ ウ";
}
.matrix li:nth-child(35):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-35 infinite steps(1, start);
  -moz-animation: 1920ms column-35 infinite steps(1, start);
  -ms-animation: 1920ms column-35 infinite steps(1, start);
  -o-animation: 1920ms column-35 infinite steps(1, start);
  animation: 1920ms column-35 infinite steps(1, start);
}

@-webkit-keyframes column-35 {
  0% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-35 {
  0% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 336px;
  }
}
@-o-keyframes column-35 {
  0% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 336px;
  }
}
@keyframes column-35 {
  0% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(36) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(36):before {
  display: block;
  width: 1em;
  content: "ワ キ フ ソ ト キ ド バ た う リ ょ ト バ ウ マ き キ ウ リ ば フ た ラ";
}
.matrix li:nth-child(36):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "キ";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-36 infinite steps(1, start);
  -moz-animation: 1920ms column-36 infinite steps(1, start);
  -ms-animation: 1920ms column-36 infinite steps(1, start);
  -o-animation: 1920ms column-36 infinite steps(1, start);
  animation: 1920ms column-36 infinite steps(1, start);
}

@-webkit-keyframes column-36 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-36 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@-o-keyframes column-36 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
@keyframes column-36 {
  0% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(37) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(37):before {
  display: block;
  width: 1em;
  content: "ょ マ ン ョ ウ ば ソ ラ ウ ワ ワ タ ラ ば ト ょ コ ト コ タ フ リ ラ マ";
}
.matrix li:nth-child(37):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(95.83333%, rgba(0, 0, 0, 0)), color-stop(95.83333%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ト";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-37 infinite steps(1, start);
  -moz-animation: 1920ms column-37 infinite steps(1, start);
  -ms-animation: 1920ms column-37 infinite steps(1, start);
  -o-animation: 1920ms column-37 infinite steps(1, start);
  animation: 1920ms column-37 infinite steps(1, start);
}

@-webkit-keyframes column-37 {
  0% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-37 {
  0% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
}
@-o-keyframes column-37 {
  0% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
}
@keyframes column-37 {
  0% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(38) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(38):before {
  display: block;
  width: 1em;
  content: "う ょ ン と ド ド ん こ シ う ラ ん シ た ラ ょ フ リ コ ド ョ ト リ ト";
}
.matrix li:nth-child(38):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "コ";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-38 infinite steps(1, start);
  -moz-animation: 1920ms column-38 infinite steps(1, start);
  -ms-animation: 1920ms column-38 infinite steps(1, start);
  -o-animation: 1920ms column-38 infinite steps(1, start);
  animation: 1920ms column-38 infinite steps(1, start);
}

@-webkit-keyframes column-38 {
  0% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-38 {
  0% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
}
@-o-keyframes column-38 {
  0% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
}
@keyframes column-38 {
  0% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(39) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(39):before {
  display: block;
  width: 1em;
  content: "ョ ド シ ソ ド ょ マ こ キ タ ば タ バ タ う ョ わ ウ ク マ ク リ ト タ";
}
.matrix li:nth-child(39):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-39 infinite steps(1, start);
  -moz-animation: 1920ms column-39 infinite steps(1, start);
  -ms-animation: 1920ms column-39 infinite steps(1, start);
  -o-animation: 1920ms column-39 infinite steps(1, start);
  animation: 1920ms column-39 infinite steps(1, start);
}

@-webkit-keyframes column-39 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-39 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@-o-keyframes column-39 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@keyframes column-39 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(40) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(40):before {
  display: block;
  width: 1em;
  content: "う ョ し ド こ タ ワ き シ ラ シ フ マ ん シ ょ フ ば ょ シ ウ ト リ ド";
}
.matrix li:nth-child(40):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(33.33333%, rgba(0, 0, 0, 0)), color-stop(33.33333%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "シ";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-40 infinite steps(1, start);
  -moz-animation: 1920ms column-40 infinite steps(1, start);
  -ms-animation: 1920ms column-40 infinite steps(1, start);
  -o-animation: 1920ms column-40 infinite steps(1, start);
  animation: 1920ms column-40 infinite steps(1, start);
}

@-webkit-keyframes column-40 {
  0% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-40 {
  0% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
}
@-o-keyframes column-40 {
  0% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
}
@keyframes column-40 {
  0% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(41) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(41):before {
  display: block;
  width: 1em;
  content: "こ き リ ウ ば ョ ラ キ ラ き ン ょ ド ク わ き ョ リ バ ソ ョ ば と う";
}
.matrix li:nth-child(41):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "バ";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-41 infinite steps(1, start);
  -moz-animation: 1920ms column-41 infinite steps(1, start);
  -ms-animation: 1920ms column-41 infinite steps(1, start);
  -o-animation: 1920ms column-41 infinite steps(1, start);
  animation: 1920ms column-41 infinite steps(1, start);
}

@-webkit-keyframes column-41 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-41 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@-o-keyframes column-41 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
@keyframes column-41 {
  0% {
    background-position: 0 48px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(42) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(42):before {
  display: block;
  width: 1em;
  content: "た タ ょ ウ ウ と ン バ ョ う バ ん し ょ ク ば ラ と バ タ ウ ょ タ リ";
}
.matrix li:nth-child(42):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 -24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-42 infinite steps(1, start);
  -moz-animation: 1920ms column-42 infinite steps(1, start);
  -ms-animation: 1920ms column-42 infinite steps(1, start);
  -o-animation: 1920ms column-42 infinite steps(1, start);
  animation: 1920ms column-42 infinite steps(1, start);
}

@-webkit-keyframes column-42 {
  0% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-42 {
  0% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
}
@-o-keyframes column-42 {
  0% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
}
@keyframes column-42 {
  0% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(43) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(43):before {
  display: block;
  width: 1em;
  content: "ょ バ リ ラ フ し ョ ょ キ ン ソ バ フ シ フ ウ タ ん ウ ン フ う ラ こ";
}
.matrix li:nth-child(43):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-43 infinite steps(1, start);
  -moz-animation: 1920ms column-43 infinite steps(1, start);
  -ms-animation: 1920ms column-43 infinite steps(1, start);
  -o-animation: 1920ms column-43 infinite steps(1, start);
  animation: 1920ms column-43 infinite steps(1, start);
}

@-webkit-keyframes column-43 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-43 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@-o-keyframes column-43 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
@keyframes column-43 {
  0% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(44) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(44):before {
  display: block;
  width: 1em;
  content: "ウ タ し ト た と ラ と マ ド シ ョ わ ウ シ た ワ ウ う ン マ ョ ウ フ";
}
.matrix li:nth-child(44):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMzMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 360px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(33.33333%, rgba(0, 0, 0, 0)), color-stop(33.33333%, #000000), color-stop(100%, #000000)) 0 360px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 360px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 360px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 192px, #000000 192px, #000000 576px) 0 360px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "フ";
  padding-top: 552px;
  overflow: auto;
  -webkit-animation: 1920ms column-44 infinite steps(1, start);
  -moz-animation: 1920ms column-44 infinite steps(1, start);
  -ms-animation: 1920ms column-44 infinite steps(1, start);
  -o-animation: 1920ms column-44 infinite steps(1, start);
  animation: 1920ms column-44 infinite steps(1, start);
}

@-webkit-keyframes column-44 {
  0% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
}
@-moz-keyframes column-44 {
  0% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
}
@-o-keyframes column-44 {
  0% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
}
@keyframes column-44 {
  0% {
    background-position: 0 360px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 288px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 312px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 336px;
    padding-top: 528px;
  }
}
.matrix li:nth-child(45) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(45):before {
  display: block;
  width: 1em;
  content: "キ ウ こ ド ょ ワ ソ リ し ウ ド た ョ ソ き マ ょ ソ タ と ウ こ ウ バ";
}
.matrix li:nth-child(45):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(95.83333%, rgba(0, 0, 0, 0)), color-stop(95.83333%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 216px;
  overflow: auto;
  -webkit-animation: 1920ms column-45 infinite steps(1, start);
  -moz-animation: 1920ms column-45 infinite steps(1, start);
  -ms-animation: 1920ms column-45 infinite steps(1, start);
  -o-animation: 1920ms column-45 infinite steps(1, start);
  animation: 1920ms column-45 infinite steps(1, start);
}

@-webkit-keyframes column-45 {
  0% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
}
@-moz-keyframes column-45 {
  0% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
}
@-o-keyframes column-45 {
  0% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
}
@keyframes column-45 {
  0% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
}
.matrix li:nth-child(46) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(46):before {
  display: block;
  width: 1em;
  content: "シ こ ョ ド タ と タ ン ソ ク キ リ た わ リ ド と コ た ソ し ょ ば ウ";
}
.matrix li:nth-child(46):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(41.66667%, rgba(0, 0, 0, 0)), color-stop(41.66667%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "リ";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-46 infinite steps(1, start);
  -moz-animation: 1920ms column-46 infinite steps(1, start);
  -ms-animation: 1920ms column-46 infinite steps(1, start);
  -o-animation: 1920ms column-46 infinite steps(1, start);
  animation: 1920ms column-46 infinite steps(1, start);
}

@-webkit-keyframes column-46 {
  0% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-46 {
  0% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
}
@-o-keyframes column-46 {
  0% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
}
@keyframes column-46 {
  0% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(47) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(47):before {
  display: block;
  width: 1em;
  content: "ウ う シ ば ウ ト ウ ト う ウ ラ バ キ マ ン シ フ た ト タ と ワ ョ ト";
}
.matrix li:nth-child(47):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -432px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(79.16667%, rgba(0, 0, 0, 0)), color-stop(79.16667%, #000000), color-stop(100%, #000000)) 0 -432px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -432px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -432px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 456px, #000000 456px, #000000 576px) 0 -432px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-47 infinite steps(1, start);
  -moz-animation: 1920ms column-47 infinite steps(1, start);
  -ms-animation: 1920ms column-47 infinite steps(1, start);
  -o-animation: 1920ms column-47 infinite steps(1, start);
  animation: 1920ms column-47 infinite steps(1, start);
}

@-webkit-keyframes column-47 {
  0% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-47 {
  0% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
}
@-o-keyframes column-47 {
  0% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
}
@keyframes column-47 {
  0% {
    background-position: 0 -432px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -408px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -384px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -456px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(48) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(48):before {
  display: block;
  width: 1em;
  content: "マ ワ ょ ウ ソ た ば う ば ョ う ラ ョ う ラ ド ラ タ ョ き う こ ョ た";
}
.matrix li:nth-child(48):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-48 infinite steps(1, start);
  -moz-animation: 1920ms column-48 infinite steps(1, start);
  -ms-animation: 1920ms column-48 infinite steps(1, start);
  -o-animation: 1920ms column-48 infinite steps(1, start);
  animation: 1920ms column-48 infinite steps(1, start);
}

@-webkit-keyframes column-48 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-48 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-o-keyframes column-48 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@keyframes column-48 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(49) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(49):before {
  display: block;
  width: 1em;
  content: "タ マ ン ん ン ン ソ と ョ し シ ん こ ん た と う ん ょ こ マ タ リ ょ";
}
.matrix li:nth-child(49):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "マ";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-49 infinite steps(1, start);
  -moz-animation: 1920ms column-49 infinite steps(1, start);
  -ms-animation: 1920ms column-49 infinite steps(1, start);
  -o-animation: 1920ms column-49 infinite steps(1, start);
  animation: 1920ms column-49 infinite steps(1, start);
}

@-webkit-keyframes column-49 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-49 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@-o-keyframes column-49 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
@keyframes column-49 {
  0% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(50) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(50):before {
  display: block;
  width: 1em;
  content: "ょ ト タ ば ウ た ソ き ょ シ ば ョ ウ ウ と し う ば ラ し た ド ん ン";
}
.matrix li:nth-child(50):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "と";
  padding-top: 336px;
  overflow: auto;
  -webkit-animation: 1920ms column-50 infinite steps(1, start);
  -moz-animation: 1920ms column-50 infinite steps(1, start);
  -ms-animation: 1920ms column-50 infinite steps(1, start);
  -o-animation: 1920ms column-50 infinite steps(1, start);
  animation: 1920ms column-50 infinite steps(1, start);
}

@-webkit-keyframes column-50 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@-moz-keyframes column-50 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@-o-keyframes column-50 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
@keyframes column-50 {
  0% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  4.16667% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  8.33333% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  12.5% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  16.66667% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  20.83333% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  25% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  29.16667% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  33.33333% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  37.5% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  41.66667% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  45.83333% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  50% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  54.16667% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  58.33333% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  62.5% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  66.66667% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  70.83333% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  75% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  79.16667% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  83.33333% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  87.5% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  91.66667% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  95.83333% {
    background-position: 0 192px;
    padding-top: 312px;
  }
}
.matrix li:nth-child(51) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(51):before {
  display: block;
  width: 1em;
  content: "ワ と ト ん し ド ょ リ き タ と シ ラ し フ タ き た ょ ワ ん ド ョ ン";
}
.matrix li:nth-child(51):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 288px;
  overflow: auto;
  -webkit-animation: 1920ms column-51 infinite steps(1, start);
  -moz-animation: 1920ms column-51 infinite steps(1, start);
  -ms-animation: 1920ms column-51 infinite steps(1, start);
  -o-animation: 1920ms column-51 infinite steps(1, start);
  animation: 1920ms column-51 infinite steps(1, start);
}

@-webkit-keyframes column-51 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-moz-keyframes column-51 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-o-keyframes column-51 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@keyframes column-51 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
.matrix li:nth-child(52) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(52):before {
  display: block;
  width: 1em;
  content: "し し バ ソ シ ウ ょ う ン ョ ク ウ コ こ リ シ ン ょ ク タ ン タ ン ウ";
}
.matrix li:nth-child(52):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ク";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-52 infinite steps(1, start);
  -moz-animation: 1920ms column-52 infinite steps(1, start);
  -ms-animation: 1920ms column-52 infinite steps(1, start);
  -o-animation: 1920ms column-52 infinite steps(1, start);
  animation: 1920ms column-52 infinite steps(1, start);
}

@-webkit-keyframes column-52 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-52 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@-o-keyframes column-52 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
@keyframes column-52 {
  0% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(53) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(53):before {
  display: block;
  width: 1em;
  content: "う た タ タ ウ た う ド タ タ こ ソ タ き ば う ん ド タ ラ ウ ば ラ ト";
}
.matrix li:nth-child(53):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(54.16667%, rgba(0, 0, 0, 0)), color-stop(54.16667%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-53 infinite steps(1, start);
  -moz-animation: 1920ms column-53 infinite steps(1, start);
  -ms-animation: 1920ms column-53 infinite steps(1, start);
  -o-animation: 1920ms column-53 infinite steps(1, start);
  animation: 1920ms column-53 infinite steps(1, start);
}

@-webkit-keyframes column-53 {
  0% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-53 {
  0% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 432px;
  }
}
@-o-keyframes column-53 {
  0% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 432px;
  }
}
@keyframes column-53 {
  0% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(54) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(54):before {
  display: block;
  width: 1em;
  content: "マ ョ こ フ ン ト ょ た こ ク バ き わ コ わ ウ た ソ ウ ょ し タ ョ ウ";
}
.matrix li:nth-child(54):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 264px;
  overflow: auto;
  -webkit-animation: 1920ms column-54 infinite steps(1, start);
  -moz-animation: 1920ms column-54 infinite steps(1, start);
  -ms-animation: 1920ms column-54 infinite steps(1, start);
  -o-animation: 1920ms column-54 infinite steps(1, start);
  animation: 1920ms column-54 infinite steps(1, start);
}

@-webkit-keyframes column-54 {
  0% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
}
@-moz-keyframes column-54 {
  0% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
}
@-o-keyframes column-54 {
  0% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
}
@keyframes column-54 {
  0% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
}
.matrix li:nth-child(55) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(55):before {
  display: block;
  width: 1em;
  content: "こ ょ ド ば ワ た う リ ょ ょ ワ き ラ コ こ ワ ョ た コ シ ョ ウ ん ラ";
}
.matrix li:nth-child(55):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 288px;
  overflow: auto;
  -webkit-animation: 1920ms column-55 infinite steps(1, start);
  -moz-animation: 1920ms column-55 infinite steps(1, start);
  -ms-animation: 1920ms column-55 infinite steps(1, start);
  -o-animation: 1920ms column-55 infinite steps(1, start);
  animation: 1920ms column-55 infinite steps(1, start);
}

@-webkit-keyframes column-55 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-moz-keyframes column-55 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-o-keyframes column-55 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@keyframes column-55 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
.matrix li:nth-child(56) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(56):before {
  display: block;
  width: 1em;
  content: "こ し う ょ た キ ょ た き リ バ ョ ウ コ う し フ ク う ん し し ょ バ";
}
.matrix li:nth-child(56):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -528px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(95.83333%, rgba(0, 0, 0, 0)), color-stop(95.83333%, #000000), color-stop(100%, #000000)) 0 -528px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -528px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -528px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -528px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-56 infinite steps(1, start);
  -moz-animation: 1920ms column-56 infinite steps(1, start);
  -ms-animation: 1920ms column-56 infinite steps(1, start);
  -o-animation: 1920ms column-56 infinite steps(1, start);
  animation: 1920ms column-56 infinite steps(1, start);
}

@-webkit-keyframes column-56 {
  0% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-56 {
  0% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
}
@-o-keyframes column-56 {
  0% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
}
@keyframes column-56 {
  0% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(57) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(57):before {
  display: block;
  width: 1em;
  content: "コ バ リ ば シ リ マ ョ う キ ワ ラ コ ン バ ト ド こ タ キ キ ラ フ う";
}
.matrix li:nth-child(57):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-57 infinite steps(1, start);
  -moz-animation: 1920ms column-57 infinite steps(1, start);
  -ms-animation: 1920ms column-57 infinite steps(1, start);
  -o-animation: 1920ms column-57 infinite steps(1, start);
  animation: 1920ms column-57 infinite steps(1, start);
}

@-webkit-keyframes column-57 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-57 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@-o-keyframes column-57 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@keyframes column-57 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(58) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(58):before {
  display: block;
  width: 1em;
  content: "う わ ョ ト ン き ン キ ょ フ う わ ド ソ ド ソ う キ コ う シ こ マ ド";
}
.matrix li:nth-child(58):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-58 infinite steps(1, start);
  -moz-animation: 1920ms column-58 infinite steps(1, start);
  -ms-animation: 1920ms column-58 infinite steps(1, start);
  -o-animation: 1920ms column-58 infinite steps(1, start);
  animation: 1920ms column-58 infinite steps(1, start);
}

@-webkit-keyframes column-58 {
  0% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-58 {
  0% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
}
@-o-keyframes column-58 {
  0% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
}
@keyframes column-58 {
  0% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(59) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(59):before {
  display: block;
  width: 1em;
  content: "ん ン ウ た き き ド ウ マ ワ ば リ う リ た ラ キ ワ ワ タ ん ン バ ラ";
}
.matrix li:nth-child(59):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 -120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 -120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 -120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 -120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 96px;
  overflow: auto;
  -webkit-animation: 1920ms column-59 infinite steps(1, start);
  -moz-animation: 1920ms column-59 infinite steps(1, start);
  -ms-animation: 1920ms column-59 infinite steps(1, start);
  -o-animation: 1920ms column-59 infinite steps(1, start);
  animation: 1920ms column-59 infinite steps(1, start);
}

@-webkit-keyframes column-59 {
  0% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
}
@-moz-keyframes column-59 {
  0% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
}
@-o-keyframes column-59 {
  0% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
}
@keyframes column-59 {
  0% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 384px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  83.33333% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  87.5% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
}
.matrix li:nth-child(60) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(60):before {
  display: block;
  width: 1em;
  content: "き こ う こ ウ わ バ ソ う ウ ば タ き ん ン ウ と ド ウ わ コ ワ ド う";
}
.matrix li:nth-child(60):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 264px;
  overflow: auto;
  -webkit-animation: 1920ms column-60 infinite steps(1, start);
  -moz-animation: 1920ms column-60 infinite steps(1, start);
  -ms-animation: 1920ms column-60 infinite steps(1, start);
  -o-animation: 1920ms column-60 infinite steps(1, start);
  animation: 1920ms column-60 infinite steps(1, start);
}

@-webkit-keyframes column-60 {
  0% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
}
@-moz-keyframes column-60 {
  0% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
}
@-o-keyframes column-60 {
  0% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
}
@keyframes column-60 {
  0% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  54.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  58.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  62.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
}
.matrix li:nth-child(61) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(61):before {
  display: block;
  width: 1em;
  content: "コ キ ョ ョ ん タ ょ き フ シ タ ラ リ タ ラ タ ウ ョ ウ シ う マ コ し";
}
.matrix li:nth-child(61):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -240px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -240px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -240px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -240px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -240px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 120px;
  overflow: auto;
  -webkit-animation: 1920ms column-61 infinite steps(1, start);
  -moz-animation: 1920ms column-61 infinite steps(1, start);
  -ms-animation: 1920ms column-61 infinite steps(1, start);
  -o-animation: 1920ms column-61 infinite steps(1, start);
  animation: 1920ms column-61 infinite steps(1, start);
}

@-webkit-keyframes column-61 {
  0% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
}
@-moz-keyframes column-61 {
  0% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
}
@-o-keyframes column-61 {
  0% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
}
@keyframes column-61 {
  0% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  4.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  8.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  12.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  16.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  20.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  25% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  29.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  33.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  37.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  41.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  45.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  50% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  54.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  58.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  62.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  66.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  70.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  75% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  79.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  83.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  87.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  91.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  95.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
}
.matrix li:nth-child(62) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(62):before {
  display: block;
  width: 1em;
  content: "バ シ ウ ト ョ と タ う う キ ウ ば ワ ょ ラ ド う し ん ト ソ た き わ";
}
.matrix li:nth-child(62):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 168px;
  overflow: auto;
  -webkit-animation: 1920ms column-62 infinite steps(1, start);
  -moz-animation: 1920ms column-62 infinite steps(1, start);
  -ms-animation: 1920ms column-62 infinite steps(1, start);
  -o-animation: 1920ms column-62 infinite steps(1, start);
  animation: 1920ms column-62 infinite steps(1, start);
}

@-webkit-keyframes column-62 {
  0% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
}
@-moz-keyframes column-62 {
  0% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
}
@-o-keyframes column-62 {
  0% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
}
@keyframes column-62 {
  0% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 144px;
  }
}
.matrix li:nth-child(63) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(63):before {
  display: block;
  width: 1em;
  content: "フ ウ キ ウ リ し マ タ ド ク き ン た こ コ キ た フ ラ ワ ン ウ た ワ";
}
.matrix li:nth-child(63):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-63 infinite steps(1, start);
  -moz-animation: 1920ms column-63 infinite steps(1, start);
  -ms-animation: 1920ms column-63 infinite steps(1, start);
  -o-animation: 1920ms column-63 infinite steps(1, start);
  animation: 1920ms column-63 infinite steps(1, start);
}

@-webkit-keyframes column-63 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-63 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@-o-keyframes column-63 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
@keyframes column-63 {
  0% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(64) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(64):before {
  display: block;
  width: 1em;
  content: "た う タ ン コ ョ ョ う こ タ ょ フ ン ン シ シ し と リ マ ド し ソ キ";
}
.matrix li:nth-child(64):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 240px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(20.83333%, rgba(0, 0, 0, 0)), color-stop(20.83333%, #000000), color-stop(100%, #000000)) 0 240px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 240px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 240px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 120px, #000000 120px, #000000 576px) 0 240px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "シ";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-64 infinite steps(1, start);
  -moz-animation: 1920ms column-64 infinite steps(1, start);
  -ms-animation: 1920ms column-64 infinite steps(1, start);
  -o-animation: 1920ms column-64 infinite steps(1, start);
  animation: 1920ms column-64 infinite steps(1, start);
}

@-webkit-keyframes column-64 {
  0% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-64 {
  0% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
}
@-o-keyframes column-64 {
  0% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
}
@keyframes column-64 {
  0% {
    background-position: 0 240px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 264px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 288px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 312px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 336px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 360px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 384px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 408px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 432px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 168px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 216px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(65) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(65):before {
  display: block;
  width: 1em;
  content: "ソ と ん ウ ょ た う ょ バ た リ ト う ょ タ ラ コ マ き こ し コ ョ コ";
}
.matrix li:nth-child(65):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "コ";
  padding-top: 504px;
  overflow: auto;
  -webkit-animation: 1920ms column-65 infinite steps(1, start);
  -moz-animation: 1920ms column-65 infinite steps(1, start);
  -ms-animation: 1920ms column-65 infinite steps(1, start);
  -o-animation: 1920ms column-65 infinite steps(1, start);
  animation: 1920ms column-65 infinite steps(1, start);
}

@-webkit-keyframes column-65 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@-moz-keyframes column-65 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@-o-keyframes column-65 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
@keyframes column-65 {
  0% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 480px;
  }
}
.matrix li:nth-child(66) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(66):before {
  display: block;
  width: 1em;
  content: "う タ ワ し た と ク た ん コ ウ マ タ ト た ド ウ わ た ョ マ マ ば ウ";
}
.matrix li:nth-child(66):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "コ";
  padding-top: 216px;
  overflow: auto;
  -webkit-animation: 1920ms column-66 infinite steps(1, start);
  -moz-animation: 1920ms column-66 infinite steps(1, start);
  -ms-animation: 1920ms column-66 infinite steps(1, start);
  -o-animation: 1920ms column-66 infinite steps(1, start);
  animation: 1920ms column-66 infinite steps(1, start);
}

@-webkit-keyframes column-66 {
  0% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
}
@-moz-keyframes column-66 {
  0% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
}
@-o-keyframes column-66 {
  0% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
}
@keyframes column-66 {
  0% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  62.5% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  66.66667% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  70.83333% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  75% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  79.16667% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  83.33333% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
}
.matrix li:nth-child(67) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(67):before {
  display: block;
  width: 1em;
  content: "ば リ し ョ き ワ ラ バ ワ ラ わ う と バ リ た う ソ フ バ ば う ワ き";
}
.matrix li:nth-child(67):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -216px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(50%, #000000), color-stop(100%, #000000)) 0 -216px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -216px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ョ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-67 infinite steps(1, start);
  -moz-animation: 1920ms column-67 infinite steps(1, start);
  -ms-animation: 1920ms column-67 infinite steps(1, start);
  -o-animation: 1920ms column-67 infinite steps(1, start);
  animation: 1920ms column-67 infinite steps(1, start);
}

@-webkit-keyframes column-67 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-67 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@-o-keyframes column-67 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
@keyframes column-67 {
  0% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(68) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(68):before {
  display: block;
  width: 1em;
  content: "わ バ マ ト ラ わ ソ う う ょ ン ん キ ん ラ う ウ コ き リ ん ウ シ う";
}
.matrix li:nth-child(68):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(41.66667%, rgba(0, 0, 0, 0)), color-stop(41.66667%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-68 infinite steps(1, start);
  -moz-animation: 1920ms column-68 infinite steps(1, start);
  -ms-animation: 1920ms column-68 infinite steps(1, start);
  -o-animation: 1920ms column-68 infinite steps(1, start);
  animation: 1920ms column-68 infinite steps(1, start);
}

@-webkit-keyframes column-68 {
  0% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-68 {
  0% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
}
@-o-keyframes column-68 {
  0% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
}
@keyframes column-68 {
  0% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(69) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(69):before {
  display: block;
  width: 1em;
  content: "タ き ョ ョ コ た こ た バ バ ば タ ょ ン た シ バ こ う ウ た ン ク ワ";
}
.matrix li:nth-child(69):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 432px;
  overflow: auto;
  -webkit-animation: 1920ms column-69 infinite steps(1, start);
  -moz-animation: 1920ms column-69 infinite steps(1, start);
  -ms-animation: 1920ms column-69 infinite steps(1, start);
  -o-animation: 1920ms column-69 infinite steps(1, start);
  animation: 1920ms column-69 infinite steps(1, start);
}

@-webkit-keyframes column-69 {
  0% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
}
@-moz-keyframes column-69 {
  0% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
}
@-o-keyframes column-69 {
  0% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
}
@keyframes column-69 {
  0% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
}
.matrix li:nth-child(70) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(70):before {
  display: block;
  width: 1em;
  content: "う ょ う た タ と ソ ョ ョ フ ョ ン た バ う ン ば ソ ょ タ た う コ た";
}
.matrix li:nth-child(70):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(54.16667%, rgba(0, 0, 0, 0)), color-stop(54.16667%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ソ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-70 infinite steps(1, start);
  -moz-animation: 1920ms column-70 infinite steps(1, start);
  -ms-animation: 1920ms column-70 infinite steps(1, start);
  -o-animation: 1920ms column-70 infinite steps(1, start);
  animation: 1920ms column-70 infinite steps(1, start);
}

@-webkit-keyframes column-70 {
  0% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-70 {
  0% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 384px;
  }
}
@-o-keyframes column-70 {
  0% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 384px;
  }
}
@keyframes column-70 {
  0% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(71) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(71):before {
  display: block;
  width: 1em;
  content: "キ マ う ョ ク コ ワ ク コ シ わ シ ョ タ キ ョ う こ こ ょ ク ん ウ ょ";
}
.matrix li:nth-child(71):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 312px;
  overflow: auto;
  -webkit-animation: 1920ms column-71 infinite steps(1, start);
  -moz-animation: 1920ms column-71 infinite steps(1, start);
  -ms-animation: 1920ms column-71 infinite steps(1, start);
  -o-animation: 1920ms column-71 infinite steps(1, start);
  animation: 1920ms column-71 infinite steps(1, start);
}

@-webkit-keyframes column-71 {
  0% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
}
@-moz-keyframes column-71 {
  0% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
}
@-o-keyframes column-71 {
  0% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
}
@keyframes column-71 {
  0% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  4.16667% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  8.33333% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  12.5% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  16.66667% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  20.83333% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  25% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  29.16667% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  33.33333% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  37.5% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  41.66667% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  45.83333% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  50% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
  54.16667% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  58.33333% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  62.5% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  66.66667% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  70.83333% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  75% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  79.16667% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  83.33333% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  87.5% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  91.66667% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  95.83333% {
    background-position: 0 120px;
    padding-top: 288px;
  }
}
.matrix li:nth-child(72) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(72):before {
  display: block;
  width: 1em;
  content: "バ コ ン ラ た タ ョ き ト タ リ タ フ ョ ド キ タ ょ き シ ウ と ょ ョ";
}
.matrix li:nth-child(72):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-72 infinite steps(1, start);
  -moz-animation: 1920ms column-72 infinite steps(1, start);
  -ms-animation: 1920ms column-72 infinite steps(1, start);
  -o-animation: 1920ms column-72 infinite steps(1, start);
  animation: 1920ms column-72 infinite steps(1, start);
}

@-webkit-keyframes column-72 {
  0% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-72 {
  0% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
}
@-o-keyframes column-72 {
  0% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
}
@keyframes column-72 {
  0% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(73) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(73):before {
  display: block;
  width: 1em;
  content: "フ ウ う リ ウ ク タ ョ ン ン リ キ ョ ウ う た こ ョ ト ょ ラ リ リ こ";
}
.matrix li:nth-child(73):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "こ";
  padding-top: 552px;
  overflow: auto;
  -webkit-animation: 1920ms column-73 infinite steps(1, start);
  -moz-animation: 1920ms column-73 infinite steps(1, start);
  -ms-animation: 1920ms column-73 infinite steps(1, start);
  -o-animation: 1920ms column-73 infinite steps(1, start);
  animation: 1920ms column-73 infinite steps(1, start);
}

@-webkit-keyframes column-73 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@-moz-keyframes column-73 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@-o-keyframes column-73 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
@keyframes column-73 {
  0% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  4.16667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  8.33333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  12.5% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  16.66667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  20.83333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  25% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
}
.matrix li:nth-child(74) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(74):before {
  display: block;
  width: 1em;
  content: "ョ ん ョ わ と ク ラ フ ウ わ リ ン う ン ク タ キ タ ワ バ ん タ ョ ク";
}
.matrix li:nth-child(74):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(41.66667%, rgba(0, 0, 0, 0)), color-stop(41.66667%, #000000), color-stop(100%, #000000)) 0 -192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 -192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ョ";
  padding-top: 48px;
  overflow: auto;
  -webkit-animation: 1920ms column-74 infinite steps(1, start);
  -moz-animation: 1920ms column-74 infinite steps(1, start);
  -ms-animation: 1920ms column-74 infinite steps(1, start);
  -o-animation: 1920ms column-74 infinite steps(1, start);
  animation: 1920ms column-74 infinite steps(1, start);
}

@-webkit-keyframes column-74 {
  0% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
}
@-moz-keyframes column-74 {
  0% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
}
@-o-keyframes column-74 {
  0% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
}
@keyframes column-74 {
  0% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 96px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
}
.matrix li:nth-child(75) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(75):before {
  display: block;
  width: 1em;
  content: "キ た フ し シ ン こ う き マ ウ ン わ う き う こ リ リ キ シ ソ う た";
}
.matrix li:nth-child(75):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNjYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(66.66667%, rgba(0, 0, 0, 0)), color-stop(66.66667%, #000000), color-stop(100%, #000000)) 0 72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 384px, #000000 384px, #000000 576px) 0 72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "キ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-75 infinite steps(1, start);
  -moz-animation: 1920ms column-75 infinite steps(1, start);
  -ms-animation: 1920ms column-75 infinite steps(1, start);
  -o-animation: 1920ms column-75 infinite steps(1, start);
  animation: 1920ms column-75 infinite steps(1, start);
}

@-webkit-keyframes column-75 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-75 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@-o-keyframes column-75 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
@keyframes column-75 {
  0% {
    background-position: 0 72px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 96px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 120px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 144px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 168px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -384px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -360px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -336px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -312px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -288px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -264px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -240px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -216px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -192px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -168px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -144px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -120px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -96px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -72px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -48px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 0px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 48px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(76) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(76):before {
  display: block;
  width: 1em;
  content: "ば シ キ わ タ タ と バ し ン リ ば タ わ ラ し た ン ょ マ ワ た ド ウ";
}
.matrix li:nth-child(76):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 504px;
  overflow: auto;
  -webkit-animation: 1920ms column-76 infinite steps(1, start);
  -moz-animation: 1920ms column-76 infinite steps(1, start);
  -ms-animation: 1920ms column-76 infinite steps(1, start);
  -o-animation: 1920ms column-76 infinite steps(1, start);
  animation: 1920ms column-76 infinite steps(1, start);
}

@-webkit-keyframes column-76 {
  0% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
}
@-moz-keyframes column-76 {
  0% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
}
@-o-keyframes column-76 {
  0% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
}
@keyframes column-76 {
  0% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  12.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  16.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  20.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  25% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  29.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  33.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  37.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  41.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  45.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  50% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  54.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
}
.matrix li:nth-child(77) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(77):before {
  display: block;
  width: 1em;
  content: "ン こ マ ょ う こ た ト こ シ う ワ た う ソ ん バ ワ ク ん ト ド ば ョ";
}
.matrix li:nth-child(77):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNjIuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI2Mi41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(62.5%, rgba(0, 0, 0, 0)), color-stop(62.5%, #000000), color-stop(100%, #000000)) 0 -168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 360px, #000000 360px, #000000 576px) 0 -168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "こ";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-77 infinite steps(1, start);
  -moz-animation: 1920ms column-77 infinite steps(1, start);
  -ms-animation: 1920ms column-77 infinite steps(1, start);
  -o-animation: 1920ms column-77 infinite steps(1, start);
  animation: 1920ms column-77 infinite steps(1, start);
}

@-webkit-keyframes column-77 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-77 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@-o-keyframes column-77 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
@keyframes column-77 {
  0% {
    background-position: 0 -168px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -360px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -336px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(78) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(78):before {
  display: block;
  width: 1em;
  content: "タ き ょ ン タ タ た ン う ウ シ ク フ マ と タ ん ワ こ シ シ う し フ";
}
.matrix li:nth-child(78):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "し";
  padding-top: 528px;
  overflow: auto;
  -webkit-animation: 1920ms column-78 infinite steps(1, start);
  -moz-animation: 1920ms column-78 infinite steps(1, start);
  -ms-animation: 1920ms column-78 infinite steps(1, start);
  -o-animation: 1920ms column-78 infinite steps(1, start);
  animation: 1920ms column-78 infinite steps(1, start);
}

@-webkit-keyframes column-78 {
  0% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 504px;
  }
}
@-moz-keyframes column-78 {
  0% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 504px;
  }
}
@-o-keyframes column-78 {
  0% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 504px;
  }
}
@keyframes column-78 {
  0% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  8.33333% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  12.5% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  16.66667% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 504px;
  }
}
.matrix li:nth-child(79) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(79):before {
  display: block;
  width: 1em;
  content: "ク ト タ ン ソ タ う ょ た し フ た ラ き た ん ン ん タ マ ク ン ン た";
}
.matrix li:nth-child(79):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTUuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -408px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(95.83333%, rgba(0, 0, 0, 0)), color-stop(95.83333%, #000000), color-stop(100%, #000000)) 0 -408px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -408px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -408px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 552px, #000000 552px, #000000 576px) 0 -408px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "う";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-79 infinite steps(1, start);
  -moz-animation: 1920ms column-79 infinite steps(1, start);
  -ms-animation: 1920ms column-79 infinite steps(1, start);
  -o-animation: 1920ms column-79 infinite steps(1, start);
  animation: 1920ms column-79 infinite steps(1, start);
}

@-webkit-keyframes column-79 {
  0% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-79 {
  0% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
}
@-o-keyframes column-79 {
  0% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
}
@keyframes column-79 {
  0% {
    background-position: 0 -408px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -384px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -360px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -336px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -312px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -288px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -264px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 -240px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 -216px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 -192px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 -168px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 -144px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 -120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 -96px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 -72px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 -48px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 -24px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 0px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -552px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -528px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -504px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -480px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -456px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -432px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(80) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(80):before {
  display: block;
  width: 1em;
  content: "タ ん ョ ョ う ソ ラ マ フ ん ょ タ ョ ョ シ き う シ バ タ こ タ タ し";
}
.matrix li:nth-child(80):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -144px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -144px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -144px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -144px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -144px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "き";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-80 infinite steps(1, start);
  -moz-animation: 1920ms column-80 infinite steps(1, start);
  -ms-animation: 1920ms column-80 infinite steps(1, start);
  -o-animation: 1920ms column-80 infinite steps(1, start);
  animation: 1920ms column-80 infinite steps(1, start);
}

@-webkit-keyframes column-80 {
  0% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-80 {
  0% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
}
@-o-keyframes column-80 {
  0% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
}
@keyframes column-80 {
  0% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(81) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(81):before {
  display: block;
  width: 1em;
  content: "タ ソ き ラ た ょ と フ た ん タ ト ょ と わ た ょ フ ク た コ こ き う";
}
.matrix li:nth-child(81):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 -120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 -120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ソ";
  padding-top: 24px;
  overflow: auto;
  -webkit-animation: 1920ms column-81 infinite steps(1, start);
  -moz-animation: 1920ms column-81 infinite steps(1, start);
  -ms-animation: 1920ms column-81 infinite steps(1, start);
  -o-animation: 1920ms column-81 infinite steps(1, start);
  animation: 1920ms column-81 infinite steps(1, start);
}

@-webkit-keyframes column-81 {
  0% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
}
@-moz-keyframes column-81 {
  0% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
}
@-o-keyframes column-81 {
  0% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
}
@keyframes column-81 {
  0% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  91.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
}
.matrix li:nth-child(82) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(82):before {
  display: block;
  width: 1em;
  content: "フ ク と コ た キ ょ ウ キ わ フ ば タ シ コ コ タ バ た ワ リ キ ョ コ";
}
.matrix li:nth-child(82):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 -24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 -24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "バ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-82 infinite steps(1, start);
  -moz-animation: 1920ms column-82 infinite steps(1, start);
  -ms-animation: 1920ms column-82 infinite steps(1, start);
  -o-animation: 1920ms column-82 infinite steps(1, start);
  animation: 1920ms column-82 infinite steps(1, start);
}

@-webkit-keyframes column-82 {
  0% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-82 {
  0% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
}
@-o-keyframes column-82 {
  0% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
}
@keyframes column-82 {
  0% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 0px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(83) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(83):before {
  display: block;
  width: 1em;
  content: "ト と ウ こ マ と ン ょ ん ウ ラ ソ ラ タ マ し ン た し バ タ ド た き";
}
.matrix li:nth-child(83):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iOTEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -288px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(91.66667%, rgba(0, 0, 0, 0)), color-stop(91.66667%, #000000), color-stop(100%, #000000)) 0 -288px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -288px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -288px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 528px, #000000 528px, #000000 576px) 0 -288px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-83 infinite steps(1, start);
  -moz-animation: 1920ms column-83 infinite steps(1, start);
  -ms-animation: 1920ms column-83 infinite steps(1, start);
  -o-animation: 1920ms column-83 infinite steps(1, start);
  animation: 1920ms column-83 infinite steps(1, start);
}

@-webkit-keyframes column-83 {
  0% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-83 {
  0% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
}
@-o-keyframes column-83 {
  0% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
}
@keyframes column-83 {
  0% {
    background-position: 0 -288px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 -264px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 -240px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 -216px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 -192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 -168px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 -144px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 -120px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 -96px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 -72px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 -48px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 -24px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 0px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 24px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -528px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -504px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -480px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -456px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -432px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -408px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 -384px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 -360px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 -336px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 -312px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(84) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(84):before {
  display: block;
  width: 1em;
  content: "キ た し タ コ バ バ ウ バ ク こ ト タ う キ ド ト ョ ワ ょ ク う リ ド";
}
.matrix li:nth-child(84):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI4Ny41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(87.5%, rgba(0, 0, 0, 0)), color-stop(87.5%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 504px, #000000 504px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 168px;
  overflow: auto;
  -webkit-animation: 1920ms column-84 infinite steps(1, start);
  -moz-animation: 1920ms column-84 infinite steps(1, start);
  -ms-animation: 1920ms column-84 infinite steps(1, start);
  -o-animation: 1920ms column-84 infinite steps(1, start);
  animation: 1920ms column-84 infinite steps(1, start);
}

@-webkit-keyframes column-84 {
  0% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
}
@-moz-keyframes column-84 {
  0% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
}
@-o-keyframes column-84 {
  0% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
}
@keyframes column-84 {
  0% {
    background-position: 0 -336px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -504px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -480px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -456px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -432px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 144px;
  }
}
.matrix li:nth-child(85) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(85):before {
  display: block;
  width: 1em;
  content: "ラ ワ た ラ き き こ う タ マ ド ん と シ ラ ワ ば リ リ ん ン ョ き た";
}
.matrix li:nth-child(85):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(50%, #000000), color-stop(100%, #000000)) 0 -96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 288px, #000000 288px, #000000 576px) 0 -96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 192px;
  overflow: auto;
  -webkit-animation: 1920ms column-85 infinite steps(1, start);
  -moz-animation: 1920ms column-85 infinite steps(1, start);
  -ms-animation: 1920ms column-85 infinite steps(1, start);
  -o-animation: 1920ms column-85 infinite steps(1, start);
  animation: 1920ms column-85 infinite steps(1, start);
}

@-webkit-keyframes column-85 {
  0% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
}
@-moz-keyframes column-85 {
  0% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
}
@-o-keyframes column-85 {
  0% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
}
@keyframes column-85 {
  0% {
    background-position: 0 -96px;
    padding-top: 192px;
  }
  4.16667% {
    background-position: 0 -72px;
    padding-top: 216px;
  }
  8.33333% {
    background-position: 0 -48px;
    padding-top: 240px;
  }
  12.5% {
    background-position: 0 -24px;
    padding-top: 264px;
  }
  16.66667% {
    background-position: 0 0px;
    padding-top: 288px;
  }
  20.83333% {
    background-position: 0 24px;
    padding-top: 312px;
  }
  25% {
    background-position: 0 48px;
    padding-top: 336px;
  }
  29.16667% {
    background-position: 0 72px;
    padding-top: 360px;
  }
  33.33333% {
    background-position: 0 96px;
    padding-top: 384px;
  }
  37.5% {
    background-position: 0 120px;
    padding-top: 408px;
  }
  41.66667% {
    background-position: 0 144px;
    padding-top: 432px;
  }
  45.83333% {
    background-position: 0 168px;
    padding-top: 456px;
  }
  50% {
    background-position: 0 192px;
    padding-top: 480px;
  }
  54.16667% {
    background-position: 0 216px;
    padding-top: 504px;
  }
  58.33333% {
    background-position: 0 240px;
    padding-top: 528px;
  }
  62.5% {
    background-position: 0 264px;
    padding-top: 552px;
  }
  66.66667% {
    background-position: 0 -288px;
    padding-top: 0px;
  }
  70.83333% {
    background-position: 0 -264px;
    padding-top: 24px;
  }
  75% {
    background-position: 0 -240px;
    padding-top: 48px;
  }
  79.16667% {
    background-position: 0 -216px;
    padding-top: 72px;
  }
  83.33333% {
    background-position: 0 -192px;
    padding-top: 96px;
  }
  87.5% {
    background-position: 0 -168px;
    padding-top: 120px;
  }
  91.66667% {
    background-position: 0 -144px;
    padding-top: 144px;
  }
  95.83333% {
    background-position: 0 -120px;
    padding-top: 168px;
  }
}
.matrix li:nth-child(86) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(86):before {
  display: block;
  width: 1em;
  content: "フ ょ ソ シ マ ン ト わ バ ょ ラ ウ う ト フ タ わ ド ド シ わ し ソ ワ";
}
.matrix li:nth-child(86):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTQuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -168px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(54.16667%, rgba(0, 0, 0, 0)), color-stop(54.16667%, #000000), color-stop(100%, #000000)) 0 -168px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 312px, #000000 312px, #000000 576px) 0 -168px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ト";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-86 infinite steps(1, start);
  -moz-animation: 1920ms column-86 infinite steps(1, start);
  -ms-animation: 1920ms column-86 infinite steps(1, start);
  -o-animation: 1920ms column-86 infinite steps(1, start);
  animation: 1920ms column-86 infinite steps(1, start);
}

@-webkit-keyframes column-86 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-86 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@-o-keyframes column-86 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
@keyframes column-86 {
  0% {
    background-position: 0 -168px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 -144px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 -120px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 -96px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 -72px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 -48px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 -24px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 0px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 24px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 48px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 72px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 96px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 120px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 144px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 168px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 192px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 216px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 240px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -312px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -288px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -264px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -240px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 -216px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 -192px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(87) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(87):before {
  display: block;
  width: 1em;
  content: "ば ン ョ タ フ ョ と た タ バ バ バ ラ ソ マ タ ン ウ フ し ラ と リ マ";
}
.matrix li:nth-child(87):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMzcuNSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSIzNy41JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==') 0 192px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(37.5%, rgba(0, 0, 0, 0)), color-stop(37.5%, #000000), color-stop(100%, #000000)) 0 192px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 192px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 192px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 216px, #000000 216px, #000000 576px) 0 192px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-87 infinite steps(1, start);
  -moz-animation: 1920ms column-87 infinite steps(1, start);
  -ms-animation: 1920ms column-87 infinite steps(1, start);
  -o-animation: 1920ms column-87 infinite steps(1, start);
  animation: 1920ms column-87 infinite steps(1, start);
}

@-webkit-keyframes column-87 {
  0% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-87 {
  0% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 384px;
  }
}
@-o-keyframes column-87 {
  0% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 384px;
  }
}
@keyframes column-87 {
  0% {
    background-position: 0 192px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 216px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 240px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 264px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 288px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 312px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 336px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -216px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -192px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -168px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -144px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -120px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -96px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -72px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -48px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -24px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 0px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 24px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 48px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 72px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 96px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 120px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 144px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 168px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(88) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(88):before {
  display: block;
  width: 1em;
  content: "リ リ キ ン キ き こ ソ タ ョ ョ ば き タ ク ョ シ と ン ラ ょ ト と ワ";
}
.matrix li:nth-child(88):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 264px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 264px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 264px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "と";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-88 infinite steps(1, start);
  -moz-animation: 1920ms column-88 infinite steps(1, start);
  -ms-animation: 1920ms column-88 infinite steps(1, start);
  -o-animation: 1920ms column-88 infinite steps(1, start);
  animation: 1920ms column-88 infinite steps(1, start);
}

@-webkit-keyframes column-88 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-88 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@-o-keyframes column-88 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
@keyframes column-88 {
  0% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 240px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(89) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(89):before {
  display: block;
  width: 1em;
  content: "ン キ し ン ウ と ば タ う う ウ タ コ ょ ょ と ワ ク ン わ ト わ ウ ば";
}
.matrix li:nth-child(89):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(75%, rgba(0, 0, 0, 0)), color-stop(75%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 432px, #000000 432px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "わ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-89 infinite steps(1, start);
  -moz-animation: 1920ms column-89 infinite steps(1, start);
  -ms-animation: 1920ms column-89 infinite steps(1, start);
  -o-animation: 1920ms column-89 infinite steps(1, start);
  animation: 1920ms column-89 infinite steps(1, start);
}

@-webkit-keyframes column-89 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-89 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@-o-keyframes column-89 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
@keyframes column-89 {
  0% {
    background-position: 0 24px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -432px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -408px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -384px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -360px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -336px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -312px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -288px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -264px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -240px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -216px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -192px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -168px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(90) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(90):before {
  display: block;
  width: 1em;
  content: "ン わ ン コ し キ キ わ ウ ラ と こ リ ン タ わ わ ト ョ ン タ と ウ ん";
}
.matrix li:nth-child(90):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTYuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(16.66667%, rgba(0, 0, 0, 0)), color-stop(16.66667%, #000000), color-stop(100%, #000000)) 0 48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 96px, #000000 96px, #000000 576px) 0 48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "キ";
  padding-top: 144px;
  overflow: auto;
  -webkit-animation: 1920ms column-90 infinite steps(1, start);
  -moz-animation: 1920ms column-90 infinite steps(1, start);
  -ms-animation: 1920ms column-90 infinite steps(1, start);
  -o-animation: 1920ms column-90 infinite steps(1, start);
  animation: 1920ms column-90 infinite steps(1, start);
}

@-webkit-keyframes column-90 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@-moz-keyframes column-90 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@-o-keyframes column-90 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
@keyframes column-90 {
  0% {
    background-position: 0 48px;
    padding-top: 144px;
  }
  4.16667% {
    background-position: 0 72px;
    padding-top: 168px;
  }
  8.33333% {
    background-position: 0 96px;
    padding-top: 192px;
  }
  12.5% {
    background-position: 0 120px;
    padding-top: 216px;
  }
  16.66667% {
    background-position: 0 144px;
    padding-top: 240px;
  }
  20.83333% {
    background-position: 0 168px;
    padding-top: 264px;
  }
  25% {
    background-position: 0 192px;
    padding-top: 288px;
  }
  29.16667% {
    background-position: 0 216px;
    padding-top: 312px;
  }
  33.33333% {
    background-position: 0 240px;
    padding-top: 336px;
  }
  37.5% {
    background-position: 0 264px;
    padding-top: 360px;
  }
  41.66667% {
    background-position: 0 288px;
    padding-top: 384px;
  }
  45.83333% {
    background-position: 0 312px;
    padding-top: 408px;
  }
  50% {
    background-position: 0 336px;
    padding-top: 432px;
  }
  54.16667% {
    background-position: 0 360px;
    padding-top: 456px;
  }
  58.33333% {
    background-position: 0 384px;
    padding-top: 480px;
  }
  62.5% {
    background-position: 0 408px;
    padding-top: 504px;
  }
  66.66667% {
    background-position: 0 432px;
    padding-top: 528px;
  }
  70.83333% {
    background-position: 0 456px;
    padding-top: 552px;
  }
  75% {
    background-position: 0 -96px;
    padding-top: 0px;
  }
  79.16667% {
    background-position: 0 -72px;
    padding-top: 24px;
  }
  83.33333% {
    background-position: 0 -48px;
    padding-top: 48px;
  }
  87.5% {
    background-position: 0 -24px;
    padding-top: 72px;
  }
  91.66667% {
    background-position: 0 0px;
    padding-top: 96px;
  }
  95.83333% {
    background-position: 0 24px;
    padding-top: 120px;
  }
}
.matrix li:nth-child(91) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(91):before {
  display: block;
  width: 1em;
  content: "ょ ば ョ リ ウ わ フ ソ ン ョ コ ド ト ト ソ き ラ タ リ た ウ タ ョ ン";
}
.matrix li:nth-child(91):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iODMuMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -72px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(83.33333%, rgba(0, 0, 0, 0)), color-stop(83.33333%, #000000), color-stop(100%, #000000)) 0 -72px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 480px, #000000 480px, #000000 576px) 0 -72px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 408px;
  overflow: auto;
  -webkit-animation: 1920ms column-91 infinite steps(1, start);
  -moz-animation: 1920ms column-91 infinite steps(1, start);
  -ms-animation: 1920ms column-91 infinite steps(1, start);
  -o-animation: 1920ms column-91 infinite steps(1, start);
  animation: 1920ms column-91 infinite steps(1, start);
}

@-webkit-keyframes column-91 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@-moz-keyframes column-91 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@-o-keyframes column-91 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
@keyframes column-91 {
  0% {
    background-position: 0 -72px;
    padding-top: 408px;
  }
  4.16667% {
    background-position: 0 -48px;
    padding-top: 432px;
  }
  8.33333% {
    background-position: 0 -24px;
    padding-top: 456px;
  }
  12.5% {
    background-position: 0 0px;
    padding-top: 480px;
  }
  16.66667% {
    background-position: 0 24px;
    padding-top: 504px;
  }
  20.83333% {
    background-position: 0 48px;
    padding-top: 528px;
  }
  25% {
    background-position: 0 72px;
    padding-top: 552px;
  }
  29.16667% {
    background-position: 0 -480px;
    padding-top: 0px;
  }
  33.33333% {
    background-position: 0 -456px;
    padding-top: 24px;
  }
  37.5% {
    background-position: 0 -432px;
    padding-top: 48px;
  }
  41.66667% {
    background-position: 0 -408px;
    padding-top: 72px;
  }
  45.83333% {
    background-position: 0 -384px;
    padding-top: 96px;
  }
  50% {
    background-position: 0 -360px;
    padding-top: 120px;
  }
  54.16667% {
    background-position: 0 -336px;
    padding-top: 144px;
  }
  58.33333% {
    background-position: 0 -312px;
    padding-top: 168px;
  }
  62.5% {
    background-position: 0 -288px;
    padding-top: 192px;
  }
  66.66667% {
    background-position: 0 -264px;
    padding-top: 216px;
  }
  70.83333% {
    background-position: 0 -240px;
    padding-top: 240px;
  }
  75% {
    background-position: 0 -216px;
    padding-top: 264px;
  }
  79.16667% {
    background-position: 0 -192px;
    padding-top: 288px;
  }
  83.33333% {
    background-position: 0 -168px;
    padding-top: 312px;
  }
  87.5% {
    background-position: 0 -144px;
    padding-top: 336px;
  }
  91.66667% {
    background-position: 0 -120px;
    padding-top: 360px;
  }
  95.83333% {
    background-position: 0 -96px;
    padding-top: 384px;
  }
}
.matrix li:nth-child(92) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(92):before {
  display: block;
  width: 1em;
  content: "ョ ワ ょ コ ば き た ウ ウ ん ョ ョ ワ ん ワ フ ド た ソ タ し し ン タ";
}
.matrix li:nth-child(92):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "タ";
  padding-top: 456px;
  overflow: auto;
  -webkit-animation: 1920ms column-92 infinite steps(1, start);
  -moz-animation: 1920ms column-92 infinite steps(1, start);
  -ms-animation: 1920ms column-92 infinite steps(1, start);
  -o-animation: 1920ms column-92 infinite steps(1, start);
  animation: 1920ms column-92 infinite steps(1, start);
}

@-webkit-keyframes column-92 {
  0% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 432px;
  }
}
@-moz-keyframes column-92 {
  0% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 432px;
  }
}
@-o-keyframes column-92 {
  0% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 432px;
  }
}
@keyframes column-92 {
  0% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  20.83333% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  25% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  29.16667% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  33.33333% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 432px;
  }
}
.matrix li:nth-child(93) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(93):before {
  display: block;
  width: 1em;
  content: "ン キ フ し た ョ ク た ん ョ ン フ ウ ン き う コ シ キ ン ん キ ょ フ";
}
.matrix li:nth-child(93):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNTguMzMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(58.33333%, rgba(0, 0, 0, 0)), color-stop(58.33333%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 336px, #000000 336px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ウ";
  padding-top: 288px;
  overflow: auto;
  -webkit-animation: 1920ms column-93 infinite steps(1, start);
  -moz-animation: 1920ms column-93 infinite steps(1, start);
  -ms-animation: 1920ms column-93 infinite steps(1, start);
  -o-animation: 1920ms column-93 infinite steps(1, start);
  animation: 1920ms column-93 infinite steps(1, start);
}

@-webkit-keyframes column-93 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-moz-keyframes column-93 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@-o-keyframes column-93 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
@keyframes column-93 {
  0% {
    background-position: 0 -48px;
    padding-top: 288px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 312px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 336px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 360px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 384px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 408px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 432px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 456px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 480px;
  }
  37.5% {
    background-position: 0 168px;
    padding-top: 504px;
  }
  41.66667% {
    background-position: 0 192px;
    padding-top: 528px;
  }
  45.83333% {
    background-position: 0 216px;
    padding-top: 552px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 0px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 24px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 48px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 72px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 96px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 120px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 144px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 168px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 192px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 216px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 240px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 264px;
  }
}
.matrix li:nth-child(94) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(94):before {
  display: block;
  width: 1em;
  content: "タ き マ た ラ ョ マ タ ン ョ ト マ シ マ ン ば ウ ョ ョ タ ウ ョ マ シ";
}
.matrix li:nth-child(94):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "た";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-94 infinite steps(1, start);
  -moz-animation: 1920ms column-94 infinite steps(1, start);
  -ms-animation: 1920ms column-94 infinite steps(1, start);
  -o-animation: 1920ms column-94 infinite steps(1, start);
  animation: 1920ms column-94 infinite steps(1, start);
}

@-webkit-keyframes column-94 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-94 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-o-keyframes column-94 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@keyframes column-94 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(95) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(95):before {
  display: block;
  width: 1em;
  content: "タ ク ょ ク コ う タ う ク わ フ ト ョ ん た ょ シ き ん ン た う フ た";
}
.matrix li:nth-child(95):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjkuMTY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(29.16667%, rgba(0, 0, 0, 0)), color-stop(29.16667%, #000000), color-stop(100%, #000000)) 0 -120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 -120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 -120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 168px, #000000 168px, #000000 576px) 0 -120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 48px;
  overflow: auto;
  -webkit-animation: 1920ms column-95 infinite steps(1, start);
  -moz-animation: 1920ms column-95 infinite steps(1, start);
  -ms-animation: 1920ms column-95 infinite steps(1, start);
  -o-animation: 1920ms column-95 infinite steps(1, start);
  animation: 1920ms column-95 infinite steps(1, start);
}

@-webkit-keyframes column-95 {
  0% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
}
@-moz-keyframes column-95 {
  0% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
}
@-o-keyframes column-95 {
  0% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
}
@keyframes column-95 {
  0% {
    background-position: 0 -120px;
    padding-top: 48px;
  }
  4.16667% {
    background-position: 0 -96px;
    padding-top: 72px;
  }
  8.33333% {
    background-position: 0 -72px;
    padding-top: 96px;
  }
  12.5% {
    background-position: 0 -48px;
    padding-top: 120px;
  }
  16.66667% {
    background-position: 0 -24px;
    padding-top: 144px;
  }
  20.83333% {
    background-position: 0 0px;
    padding-top: 168px;
  }
  25% {
    background-position: 0 24px;
    padding-top: 192px;
  }
  29.16667% {
    background-position: 0 48px;
    padding-top: 216px;
  }
  33.33333% {
    background-position: 0 72px;
    padding-top: 240px;
  }
  37.5% {
    background-position: 0 96px;
    padding-top: 264px;
  }
  41.66667% {
    background-position: 0 120px;
    padding-top: 288px;
  }
  45.83333% {
    background-position: 0 144px;
    padding-top: 312px;
  }
  50% {
    background-position: 0 168px;
    padding-top: 336px;
  }
  54.16667% {
    background-position: 0 192px;
    padding-top: 360px;
  }
  58.33333% {
    background-position: 0 216px;
    padding-top: 384px;
  }
  62.5% {
    background-position: 0 240px;
    padding-top: 408px;
  }
  66.66667% {
    background-position: 0 264px;
    padding-top: 432px;
  }
  70.83333% {
    background-position: 0 288px;
    padding-top: 456px;
  }
  75% {
    background-position: 0 312px;
    padding-top: 480px;
  }
  79.16667% {
    background-position: 0 336px;
    padding-top: 504px;
  }
  83.33333% {
    background-position: 0 360px;
    padding-top: 528px;
  }
  87.5% {
    background-position: 0 384px;
    padding-top: 552px;
  }
  91.66667% {
    background-position: 0 -168px;
    padding-top: 0px;
  }
  95.83333% {
    background-position: 0 -144px;
    padding-top: 24px;
  }
}
.matrix li:nth-child(96) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(96):before {
  display: block;
  width: 1em;
  content: "と マ た ワ キ た ラ ン た タ ト マ タ と わ ょ キ と シ タ タ う ン ょ";
}
.matrix li:nth-child(96):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -48px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -48px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -48px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -48px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -48px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ょ";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-96 infinite steps(1, start);
  -moz-animation: 1920ms column-96 infinite steps(1, start);
  -ms-animation: 1920ms column-96 infinite steps(1, start);
  -o-animation: 1920ms column-96 infinite steps(1, start);
  animation: 1920ms column-96 infinite steps(1, start);
}

@-webkit-keyframes column-96 {
  0% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-96 {
  0% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
}
@-o-keyframes column-96 {
  0% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
}
@keyframes column-96 {
  0% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(97) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(97):before {
  display: block;
  width: 1em;
  content: "と と た わ キ ン ば ラ タ ょ ん フ ん ョ ん ン わ わ と タ マ し ョ ト";
}
.matrix li:nth-child(97):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNDEuNjY2NjclIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 120px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(41.66667%, rgba(0, 0, 0, 0)), color-stop(41.66667%, #000000), color-stop(100%, #000000)) 0 120px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 120px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 120px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 240px, #000000 240px, #000000 576px) 0 120px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ン";
  padding-top: 360px;
  overflow: auto;
  -webkit-animation: 1920ms column-97 infinite steps(1, start);
  -moz-animation: 1920ms column-97 infinite steps(1, start);
  -ms-animation: 1920ms column-97 infinite steps(1, start);
  -o-animation: 1920ms column-97 infinite steps(1, start);
  animation: 1920ms column-97 infinite steps(1, start);
}

@-webkit-keyframes column-97 {
  0% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 336px;
  }
}
@-moz-keyframes column-97 {
  0% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 336px;
  }
}
@-o-keyframes column-97 {
  0% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 336px;
  }
}
@keyframes column-97 {
  0% {
    background-position: 0 120px;
    padding-top: 360px;
  }
  4.16667% {
    background-position: 0 144px;
    padding-top: 384px;
  }
  8.33333% {
    background-position: 0 168px;
    padding-top: 408px;
  }
  12.5% {
    background-position: 0 192px;
    padding-top: 432px;
  }
  16.66667% {
    background-position: 0 216px;
    padding-top: 456px;
  }
  20.83333% {
    background-position: 0 240px;
    padding-top: 480px;
  }
  25% {
    background-position: 0 264px;
    padding-top: 504px;
  }
  29.16667% {
    background-position: 0 288px;
    padding-top: 528px;
  }
  33.33333% {
    background-position: 0 312px;
    padding-top: 552px;
  }
  37.5% {
    background-position: 0 -240px;
    padding-top: 0px;
  }
  41.66667% {
    background-position: 0 -216px;
    padding-top: 24px;
  }
  45.83333% {
    background-position: 0 -192px;
    padding-top: 48px;
  }
  50% {
    background-position: 0 -168px;
    padding-top: 72px;
  }
  54.16667% {
    background-position: 0 -144px;
    padding-top: 96px;
  }
  58.33333% {
    background-position: 0 -120px;
    padding-top: 120px;
  }
  62.5% {
    background-position: 0 -96px;
    padding-top: 144px;
  }
  66.66667% {
    background-position: 0 -72px;
    padding-top: 168px;
  }
  70.83333% {
    background-position: 0 -48px;
    padding-top: 192px;
  }
  75% {
    background-position: 0 -24px;
    padding-top: 216px;
  }
  79.16667% {
    background-position: 0 0px;
    padding-top: 240px;
  }
  83.33333% {
    background-position: 0 24px;
    padding-top: 264px;
  }
  87.5% {
    background-position: 0 48px;
    padding-top: 288px;
  }
  91.66667% {
    background-position: 0 72px;
    padding-top: 312px;
  }
  95.83333% {
    background-position: 0 96px;
    padding-top: 336px;
  }
}
.matrix li:nth-child(98) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(98):before {
  display: block;
  width: 1em;
  content: "ン た う ク コ シ し ん フ う ョ ン ソ ょ き ワ き ョ リ コ た リ ワ ト";
}
.matrix li:nth-child(98):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 96px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 96px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 96px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 96px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 96px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ョ";
  padding-top: 240px;
  overflow: auto;
  -webkit-animation: 1920ms column-98 infinite steps(1, start);
  -moz-animation: 1920ms column-98 infinite steps(1, start);
  -ms-animation: 1920ms column-98 infinite steps(1, start);
  -o-animation: 1920ms column-98 infinite steps(1, start);
  animation: 1920ms column-98 infinite steps(1, start);
}

@-webkit-keyframes column-98 {
  0% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
}
@-moz-keyframes column-98 {
  0% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
}
@-o-keyframes column-98 {
  0% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
}
@keyframes column-98 {
  0% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  4.16667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  8.33333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  12.5% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  16.66667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  20.83333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  25% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  29.16667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  33.33333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  37.5% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  41.66667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  45.83333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  50% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  54.16667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  58.33333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  62.5% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  66.66667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  70.83333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  75% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  79.16667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  83.33333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
  87.5% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  91.66667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  95.83333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
}
.matrix li:nth-child(99) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(99):before {
  display: block;
  width: 1em;
  content: "ョ ウ タ マ コ た し ワ こ ょ ン キ ョ こ う し タ タ ン ソ と ョ ド キ";
}
.matrix li:nth-child(99):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iNzAuODMzMzMlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 -336px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(70.83333%, rgba(0, 0, 0, 0)), color-stop(70.83333%, #000000), color-stop(100%, #000000)) 0 -336px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 408px, #000000 408px, #000000 576px) 0 -336px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "マ";
  padding-top: 72px;
  overflow: auto;
  -webkit-animation: 1920ms column-99 infinite steps(1, start);
  -moz-animation: 1920ms column-99 infinite steps(1, start);
  -ms-animation: 1920ms column-99 infinite steps(1, start);
  -o-animation: 1920ms column-99 infinite steps(1, start);
  animation: 1920ms column-99 infinite steps(1, start);
}

@-webkit-keyframes column-99 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-moz-keyframes column-99 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@-o-keyframes column-99 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
@keyframes column-99 {
  0% {
    background-position: 0 -336px;
    padding-top: 72px;
  }
  4.16667% {
    background-position: 0 -312px;
    padding-top: 96px;
  }
  8.33333% {
    background-position: 0 -288px;
    padding-top: 120px;
  }
  12.5% {
    background-position: 0 -264px;
    padding-top: 144px;
  }
  16.66667% {
    background-position: 0 -240px;
    padding-top: 168px;
  }
  20.83333% {
    background-position: 0 -216px;
    padding-top: 192px;
  }
  25% {
    background-position: 0 -192px;
    padding-top: 216px;
  }
  29.16667% {
    background-position: 0 -168px;
    padding-top: 240px;
  }
  33.33333% {
    background-position: 0 -144px;
    padding-top: 264px;
  }
  37.5% {
    background-position: 0 -120px;
    padding-top: 288px;
  }
  41.66667% {
    background-position: 0 -96px;
    padding-top: 312px;
  }
  45.83333% {
    background-position: 0 -72px;
    padding-top: 336px;
  }
  50% {
    background-position: 0 -48px;
    padding-top: 360px;
  }
  54.16667% {
    background-position: 0 -24px;
    padding-top: 384px;
  }
  58.33333% {
    background-position: 0 0px;
    padding-top: 408px;
  }
  62.5% {
    background-position: 0 24px;
    padding-top: 432px;
  }
  66.66667% {
    background-position: 0 48px;
    padding-top: 456px;
  }
  70.83333% {
    background-position: 0 72px;
    padding-top: 480px;
  }
  75% {
    background-position: 0 96px;
    padding-top: 504px;
  }
  79.16667% {
    background-position: 0 120px;
    padding-top: 528px;
  }
  83.33333% {
    background-position: 0 144px;
    padding-top: 552px;
  }
  87.5% {
    background-position: 0 -408px;
    padding-top: 0px;
  }
  91.66667% {
    background-position: 0 -384px;
    padding-top: 24px;
  }
  95.83333% {
    background-position: 0 -360px;
    padding-top: 48px;
  }
}
.matrix li:nth-child(100) {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.matrix li:nth-child(100):before {
  display: block;
  width: 1em;
  content: "ワ ウ た う ソ き ク ラ ソ ド た ト ょ ク タ ド た き ょ う ょ ソ こ ん";
}
.matrix li:nth-child(100):after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g') 0 24px repeat;
  background: -webkit-gradient(linear, 50% 0%, 50% 576, color-stop(0%, #000000), color-stop(25%, rgba(0, 0, 0, 0)), color-stop(25%, #000000), color-stop(100%, #000000)) 0 24px repeat;
  background: -moz-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 24px repeat;
  background: -webkit-linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 24px repeat;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 144px, #000000 144px, #000000 576px) 0 24px repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  content: "ラ";
  padding-top: 168px;
  overflow: auto;
  -webkit-animation: 1920ms column-100 infinite steps(1, start);
  -moz-animation: 1920ms column-100 infinite steps(1, start);
  -ms-animation: 1920ms column-100 infinite steps(1, start);
  -o-animation: 1920ms column-100 infinite steps(1, start);
  animation: 1920ms column-100 infinite steps(1, start);
}

@-webkit-keyframes column-100 {
  0% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
}
@-moz-keyframes column-100 {
  0% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
}
@-o-keyframes column-100 {
  0% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
}
@keyframes column-100 {
  0% {
    background-position: 0 24px;
    padding-top: 168px;
  }
  4.16667% {
    background-position: 0 48px;
    padding-top: 192px;
  }
  8.33333% {
    background-position: 0 72px;
    padding-top: 216px;
  }
  12.5% {
    background-position: 0 96px;
    padding-top: 240px;
  }
  16.66667% {
    background-position: 0 120px;
    padding-top: 264px;
  }
  20.83333% {
    background-position: 0 144px;
    padding-top: 288px;
  }
  25% {
    background-position: 0 168px;
    padding-top: 312px;
  }
  29.16667% {
    background-position: 0 192px;
    padding-top: 336px;
  }
  33.33333% {
    background-position: 0 216px;
    padding-top: 360px;
  }
  37.5% {
    background-position: 0 240px;
    padding-top: 384px;
  }
  41.66667% {
    background-position: 0 264px;
    padding-top: 408px;
  }
  45.83333% {
    background-position: 0 288px;
    padding-top: 432px;
  }
  50% {
    background-position: 0 312px;
    padding-top: 456px;
  }
  54.16667% {
    background-position: 0 336px;
    padding-top: 480px;
  }
  58.33333% {
    background-position: 0 360px;
    padding-top: 504px;
  }
  62.5% {
    background-position: 0 384px;
    padding-top: 528px;
  }
  66.66667% {
    background-position: 0 408px;
    padding-top: 552px;
  }
  70.83333% {
    background-position: 0 -144px;
    padding-top: 0px;
  }
  75% {
    background-position: 0 -120px;
    padding-top: 24px;
  }
  79.16667% {
    background-position: 0 -96px;
    padding-top: 48px;
  }
  83.33333% {
    background-position: 0 -72px;
    padding-top: 72px;
  }
  87.5% {
    background-position: 0 -48px;
    padding-top: 96px;
  }
  91.66667% {
    background-position: 0 -24px;
    padding-top: 120px;
  }
  95.83333% {
    background-position: 0 0px;
    padding-top: 144px;
  }
}

