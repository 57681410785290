.page-container {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
    
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  