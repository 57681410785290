.fullscreen-canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  min-height: 3200px;
}

body {
  margin: 0;
  padding: 0;
}

.main-content {
  /* Add margin-top to push content below the canvas */
  margin-top: 100vh;
}