.dropdown-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 20px;
}

.menu-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-links {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 200px;
  height: 100vh;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  animation: slide-in 0.5s ease forwards;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */ /* Remove this */
}

.menu-links a:last-child {
  /* margin-top: auto; */
}



.menu-links a {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.menu-links a:hover {
  color: white;
}

@keyframes slide-in {
  from {
    transform: translateX(200px);
  }
  to {
    transform: translateX(0);
  }
}
