
  
  #root {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;

  }
  
  .landing-page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .content-wrap {
    flex: 1;
  }