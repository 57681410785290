.chatbox {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 1px;
  background-color: rgba(0, 0, 0, 0.5);;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.boxpaddinglol{
  padding-top: 20px;
}
.chatbox h1 {
  color: white;
}

.chat-window {
  height: 300px;
  overflow-y: scroll;
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.chat-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
}

.chat-message.user {
  background-color: #007bff;
  color: white;
  text-align: right;
}

.chat-message.bot {
  background-color: #555;
  color: white;
  text-align: left;
}

form {
  display: flex;
}

input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;

}

button {
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}
