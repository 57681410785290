.page2-container {
    /* padding: 20px; */
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
    font-family: "Space Mono", monospace;

  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
      font-family: "Space Mono", monospace;

  }
  p{
    color: white;
    font-family: "Space Mono", monospace;

  }
  
  .developer-info {
    background: rgba(0, 0, 0, 0.5); /* Added opacity */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    font-family: "Space Mono", monospace;

  }
  
  .developer-info h2 {
    color: #007BFF;
    margin-bottom: 10px;
  }
  
  .developer-info h3 {
    color: #007BFF;
    margin-top: 20px;
  }
  
  .developer-info p {
    line-height: 1.6;
  }
  
  .developer-info ul {
    list-style-type: disc;
    padding-left: 20px;
    
  }
  
  .developer-info li {
    margin-bottom: 10px;
  }
  