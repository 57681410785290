.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 5px;
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 1.2em;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 2px solid black; /* Black ring outline */
    cursor: pointer;
  }
  
  .circle:hover {
    box-shadow: 0 0 40px 20px rgba(0, 198, 255, 0.7); /* Bright blue glow effect */
  }
  
  .circle:active {
    animation: click-animation 0.5s forwards;
  }
  
  @keyframes click-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .our-products {
    background-image: url('../assets/products.webp'); /* Path to the 'Our Products' icon */
  }
  
  .about-dev {
    background-image: url('../assets/about-dev.webp'); /* Path to the 'About the Developer' icon */
  }

  .contact-us {
    background-image: url('../assets/contact-us.webp'); 
  }
  